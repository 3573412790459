import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewContainerRef, ViewChild, ElementRef, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, } from '@angular/forms';
import { NgbDateStruct, NgbDatepickerConfig, NgbModal, ModalDismissReasons, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, } from 'rxjs/operators';
import { $, element, promise } from 'protractor';
import { ServerURLS } from 'src/environments/environment';
import { ScCommonService, UnitedStatesDateParserFormatter } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { CustomPackageComponent } from '../custom-package/custom-package.component';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: UnitedStatesDateParserFormatter }]
})
export class StepOneComponent implements OnInit {
  @ViewChild('loadContent', undefined) loadContent;
  phoneNumberDisabled: boolean = true;
  displayData = true;
  hazmatForm:FormGroup
  showMainContent: Boolean = true;
  dateVal = true;
  sidebarToggle: boolean = false;
  locationType: any;
  carrierDetailList: any = [];
  consigneeAddr: any;
  shipperAddr: any;
  manulGoogleshipperAddr: any;
  manulGoogleConsigneeAddr: any;
  acceserialStart: any[] = [];
  acceserialEnd: any[] = [];
  accessorialData: any[] = [];
  accesserialLabelStart: any;
  accesserialLabelEnd: any;
  model;
  model2;
  classToSet: any[] = [];
  height: any;
  weight: any;
  width: any;
  length: any;
  description:any;
  saveQuoteDataObj: any;
  customerDetailsByid: any
  // showSection = false;
  // showSectionButton = true;
  showDetailedAddressError = false;
  showDetailedEndAddressError = false;
  postalValid = false;
  shipmentName = '';
  shipmentStyleCode = '';
  packagingType = 'Package';
  addItemArray = [];
  globalPieceCount: any = {
    piececount: 0,
    lineaerfeet: 0,
    totalweight: 0,
    class: '',
    shipmentvalue: 0,
  };
  todaysDate: any;
  minDate: any;
  maxDate: any;
  freightClassObj: any;
  freightDisplayDataObj: any;
  changeType = false;
  dynamicItem = false;
  editQuoteItemIndex = -1;
  shipmentData: any;
  filterShipmentMode: any;
  shpmtModeId: any;
  packagingTypeId: any;
  showPackage = true;
  shipmentDataIndexKey: any;
  shipmentDetailsExists = false;
  shipmentTypeDetails: any;
  shipmentTypeId: any;
  showShipperWarning = false;
  showConsigneeWarning = false;
  showShipperWarningAdd = false;
  showConsigneeWarningAdd = false;
  showShipmentValueAdd = false;
  countryShipperString = '';
  countryConsigneeString = '';
  freightClassSelectedId: any;
  setcurrency = ['CAD'];
  latlng = [];
  mapService: any;
  shipAgainQuoteID: any;
  shipperCompanyName: any;
  consigneeCompanyName: any;
  zoom: number = 13;
  selectedPackageTypeId: any = null;
  selectedPackagingTypeStyleCode: any;
  selectedPackagingTypeName: any;
  freightClassApplicable = true;
  subPackageTypes: any;
  details: any;
  mapCoordinate;
  shipperAddress: string;
  consigneeAddress: string;
  origin: any;
  destination: any;
  displayOrder: number;
  cYear: any;
  Position: any;
  PositionError: any;
  addressData: any[];
  ConsigneeAddressData: any[];
  detailsOfCompany: {};
  shearchaddress: boolean = false;
  shearchaddress2: boolean = false;
  found: any;
  found2: any;
  isAddressBookShipper: boolean = false;
  isAddressBookConsignee: boolean = false;
  defaultaddressbookdata: any[];
  isShipmentModeSelected: boolean = false;
  shipmentModeCourier: boolean = false;
  totalOfQuantity: any = 0;
  totalOfWeight: any = 0.0;
  totalOfShipmentValue: any = 0.0;
  totalofWidth: any = 0.0;
  totalofLength: any = 0.0;
  totalofHeight: any = 0.0;
  totalClass: any;
  customerId: any;
  defaultHazmat: boolean = false;
  selectedDate: NgbDateStruct;
  ftl: any;
  customerAllData: any;
  packageModeId: any;
  shipmodeNameArray = []
  data1: any;
  serviceType: any;
  packageType: any;
  pkgType: any;
  crPkgType: any;
  businessId: any;
  filterData: any;
  firstName: any = '';
  emailAdd: any = '';
  phoneNumber: any = '';
  customerName: any;
  shipmentCode: any;
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  options = {
    types: [],
    componentRestrictions: { country: 'UA' },
  };
  address_components;
  geometry;
  data;
  addItemForm: FormGroup;
  rows: FormArray;
  shipmentItemsForm: FormGroup;
  detailsForm: FormGroup;
  submitted = false;
  selectedDay: string = '';
  measurementType = 'imperial';
  saveForLaterEnable: boolean = false;
  editOrderEnabled: boolean = false;
  editOrderNumber = '';
  quote: any;
  CourierRowCount = 0;
  densityCounter = 0;

  //@ViewChild('addressInput') addressInput: ElementRef;
  // @ViewChild('addressConsigneeInput') addressConsigneeInput: ElementRef;
  shipperFullAddress: any = '1100 King Street West, Toronto, ON, Canada';
  consigneeFullAddress: any = '1100 Union Street, San Diego, CA, USA';
  // sidebarToggle: boolean;
  //myForm: FormGroup;
  availableCreditLimitInCAD:any;
  availableCreditLimitInUSD:any;
  isCreditDisabled:boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private config: NgbDatepickerConfig,
    public formBuilder: FormBuilder,
    vcr: ViewContainerRef,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private httpService: ScHttpService,
    private commanService: ScCommonService,
    private calendar: NgbCalendar,
    private listDataService:ListDataServiceService,
    private  _loaderService: LoadingService
  ) {
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    this.config.minDate = { month: current.getMonth() + 1, day: current.getDate(), year: current.getFullYear(), };
    this.config.outsideDays = 'hidden';
    const today = new Date();
    this.selectedDate = { month: today.getMonth() + 1, day: today.getDate(), year: today.getFullYear() };
  }


  ngDoCheck() {
    if (this.sidebarToggle == true) {
      this.sidebarToggle = true;
    } else {
      this.sidebarToggle = false;
    }
  }

  //***************************************************ngOnint*********************************************************************


  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    sessionStorage.removeItem('_admincountryDataForMarchInsurance')
    sessionStorage.removeItem("_adminorderInsuranceData");
    // this.cYear = environment.Cyear;
    this.getCustomerListByName('');
    this.shipAgainQuoteID =
      this.activatedRoute.snapshot.paramMap.get('quoteId');
    this.openModel();

    this.accesserialLabelStart = 'Select Accessorial';
    this.accesserialLabelEnd = 'Select Accessorial';
    this.found = '';
    this.found2 = '';
    this.latlng[0] = [43.849902, -79.393173]; //set to shipcarte office for now.
    this.origin = { lat: 43.849902, lng: -79.393173 };
    this.destination = { lat: 43.849902, lng: -79.393173 };
    this.getLocation();
    this.getshipmentData();
    this.getfrieghtClass();
    this.addressData = [];
    this.ConsigneeAddressData = [];
    this.addItemForm = this.formBuilder.group({
      lengthType: [false],
      packageType: [''],
      pieceCount: [''],
      length: [''],
      width: [''],
      height: [''],
      weight: [''],
      description: [''],
      class: [''],
      nmfc: [''],
      shipmentvalue: [''],
      shipmentcurrencyValue: [this.setcurrency],
      stack: [false],
      hazmet: [false],
    });
    this.shipmentItemsForm = this.formBuilder.group({
      inputFields: this.formBuilder.array([this.addMeasureMentFormGroup()]),
    });
    // JSON.parse(
    //   localStorage.getItem('loggedInUserDetailsFullData')
    // ).loggedInUserId;

    this.shipmentItemsForm.valueChanges.subscribe((data) => {
      let quantityArr = [];
      let weightArr = [];
      let shipmentValueArr = [];
      this.totalOfQuantity = 0;
      this.totalOfShipmentValue = 0;
      this.totalOfWeight = 0;
      this.totalofWidth = 0;
      this.totalofLength = 0;
      this.totalofHeight = 0;
      for (let arr of data.inputFields) {
        quantityArr.push(arr.pieceCount);
        weightArr.push(arr.weight);
        shipmentValueArr.push(arr.shipmentvalue);
        this.totalOfWeight = this.totalOfWeight + arr.pieceCount * arr.weight;
        this.totalOfQuantity += arr.pieceCount;
        this.totalOfShipmentValue += arr.shipmentvalue;
        this.totalofWidth += arr.width;
        this.totalofLength += arr.length;
        this.totalofHeight += arr.height;
        this.totalClass = arr.class;
      }
    });

    this.detailsForm = this.formBuilder.group({
      locationTypeStart: [''],
      locationTypeEnd: [''],
      shipperAddressBook: [],
      consigneeAddressBook: [''],
      consigneeAddress: [''],
      shipperAddress: [''],
    });
    // this.common.editableCustomOrderId = '';
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.todaysDate = this.minDate = mm + '-' + dd + '-' + yyyy;
    if (this.shipAgainQuoteID == null) {
      sessionStorage.removeItem('_adminorderPickupDate');
    } else {
      if (sessionStorage.getItem('_adminorderPickupDate')) {
        let savedDateObj = JSON.parse(
          sessionStorage.getItem('_adminorderPickupDate')
        );
        if (savedDateObj.quoteId == this.shipAgainQuoteID) {
          this.todaysDate = savedDateObj.savedDate;
        } else {
          sessionStorage.removeItem('_adminorderPickupDate');
        }
      }
    }
    var maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 1);
    this.maxDate = maxDate.toISOString().slice(0, 10);
    // this.exampleOptions = {
    //   mode: 'single',
    //   dateFormat: 'm-d-Y',
    //   defaultDate: this.todaysDate,
    //   minDate: this.minDate,
    //   maxDate: this.maxDate,
    // };
    this.saveQuoteDataObj = {
      sourceAddress: {
        zipCode: '',
        city: '',
        state: '',
        country: '',
        addrTypeId: '',
        company: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: ''
      },
      destinationAddress: {
        zipCode: '',
        city: '',
        state: '',
        country: '',
        addrTypeId: '',
        company: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
      },
      delvryForm: {
        pickupDate1: {
          year: 2021,
          month: 7,
          day: 25,
        },
        pickupDate: '2021-07-25',
        pickupFromTime: 'c7b3e35a-f7ed-11e9-8462-163046d26cd4',
        pickupToTime: 'c7b3e641-f7ed-11e9-8462-163046d26cd4',
        quoteId: null,
        deliveryId: null,
      },
      items: [],
      customerId: '',
      shipmentMode: null,
      shpmtTypeId: null,
      shpmtPackagingTypeId: null,
      shipmentSubPackageCode: null,
      hasSourceServicesOpted: false,
      hasDestServicesOpted: false,
      extraServices: ['3554118c-f312-11e9-8462-163046d26cd4'],
      shipperCompanyId: null,
      consigneeCompanyId: null,
      poNumber:null,
      referenceNumber:null
    };

    this.saveQuoteDataObj['sourceAddress']['fullAddr'] = '';
    this.saveQuoteDataObj['destinationAddress']['fullAddr'] = '';
    this.saveQuoteDataObj['destinationAddress']['zipCode'] = '';
    this.saveQuoteDataObj['destinationAddress']['city'] = '';
    this.saveQuoteDataObj['destinationAddress']['state'] = '';
    this.saveQuoteDataObj['destinationAddress']['country'] = '';
    this.saveQuoteDataObj['destinationAddress']['addrTypeId'] = '';
    this.saveQuoteDataObj['destinationAddress']['firstName'] = '';
    this.saveQuoteDataObj['destinationAddress']['lastName'] = '';



    var g = document.createElement('div');
    g.setAttribute('id', 'map');
    var map = new google.maps.Map(g, {});
    this.mapService = new google.maps.places.PlacesService(map);
    this.displayOrder = 1;
  }


  getShipmentForm(): FormArray {
    return this.shipmentItemsForm.get('inputFields') as FormArray;
  }

  employeeSkills(empIndex: number): FormArray {
    return this.getShipmentForm().at(empIndex).get('hazMatDetails') as FormArray;
  }


  getAddressBookData(){
    let url = ServerURLS.getAddressBookgetquote.action + "0/20/?searchKey=&uniqueCompany=true&customerId="+this.customerId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((a) => {
      if (a['totalPages'] === 0) {
        this.defaultaddressbookdata = [];

        this.modalService.dismissAll()
        return;
      }

      this.modalService.dismissAll()
      this.defaultaddressbookdata = a['response'];
      this.addressData = this.defaultaddressbookdata;
      this.ConsigneeAddressData = this.defaultaddressbookdata;
    }).catch((error) => {

      this.modalService.dismissAll()
      if (error['status'] === 404) {

        this.modalService.dismissAll()
        return;
      }
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    })
  }

  //*************************************************** End ngOnint*********************************************************************

  // ************************************************* Api call after select the customer *********************************************
  // orderIsWithInCanada: boolean = true;
  // removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada() {
  //   if (this.selectedShipperAddressCountry && this.selectedCosigneeAddressCountry) {

  //     if (this.selectedShipperAddressCountry === 'CA' && this.selectedCosigneeAddressCountry === 'CA') {
  //       this.orderIsWithInCanada = true;
  //       this.classToSet = []
  //     } else {
  //       this.orderIsWithInCanada = false;
  //     }
  //   }
  // }



  getCustomerListByName(customerName: string) {
    this.carrierDetailList = []
    let url1 = ServerURLS.getCustomerListAdmin.action + "/0/50?status=Active&query=" + customerName;
    let promsie = this.httpService.getHttpService(url1, '');
    promsie.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      let customerList = res.response;
      customerList = customerList.filter((data => data.status !== 'INACTIVE'))
      this.carrierDetailList.push(customerList);
    })
      .catch((err: any) => {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
      })
  }

  getCustomerDetailsbyId(cutomerId) {

    this.customerId = cutomerId;
    this.shipConsAdderss(this.customerId)
    this.getAddressBookData();
    this.customerAllDetails(cutomerId);

    sessionStorage.setItem('_admincustomerId', this.customerId);

  }

  // ************************************************* End Api call after select the customer *********************************************

  //event handler for the select element's change event
  selectChangeHandler(event: any) {
    //update the ui
    this.selectedDay = event.target.value;
  }

  getLocationType(custId, busId) {
    let url = ServerURLS.getLocationTy.action + '?customerId=' + custId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      if (result['data'].length != 0) {
        this.locationType = result['data'];
        this.locationType.filter((element) => {
          if (element.isDefault == true) {
            if(this.shipAgainQuoteID == null){
              this.detailsForm.controls['locationTypeStart'].setValue(element.locId);
              this.saveQuoteDataObj['sourceAddress']['addrTypeId'] = element.locId;

                
              //this.saveQuoteDataObj['sourceAddress']['addrTypeId'] = element.locId;

            }


            if (this.shipAgainQuoteID == null) {
              var url1 = ServerURLS.getLocationExtraServices.action + '?locTypeId=' + busId + '&locType=start' + '&customerId=' + this.customerId+'&shipmentCode='+this.shipmentCode;
              let promise1 = this.httpService.getHttpService(url1, '');
              promise1.then((data) => {
                this.displayData = false;
                this._loaderService.stringSubject.next(false);
                this.acceserialStart = [];
                this.accessorialData['start'] = data['data'];
                this.accesserialLabelStart = '';
                this.accessorialData['start'].forEach((element) => {
                  if (element.isDefault == true) {
                    this.acceserialStart.push(element);
                  }
                });
              })
            }
          }
        });
      }
    })
  }


  shipConsAdderss(customerId) {
    let url2 = ServerURLS.getShipperConsigneeAddress.action + '?customerId=' + customerId;
    let promise2 = this.httpService.getHttpService(url2, '');
    promise2.then((data) => {
      if (data) {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.shipperAddr = data['shipper_address'];
      this.consigneeAddr = data['consignee_address'];
      this.selectedCosigneeAddressCountry = data['consignee_address'].country;
      this.selectedShipperAddressCountry = data['shipper_address'].country;
      // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
      this.manulGoogleshipperAddr = data['shipper_address'].googleMapsEnabled;
      this.manulGoogleConsigneeAddr = data['consignee_address'].googleMapsEnabled;
      // this.saveQuoteDataObj['sourceAddress']['fullAddr'] = this.shipperAddr.addrLine1 ? this.shipperAddr.addrLine1 : '';
      this.shipperFullAddr = this.shipperAddr.fullAddr  ? this.shipperAddr.fullAddr  : '';
      this.saveQuoteDataObj['sourceAddress']['zipCode'] = this.shipperAddr.zipCode ? this.shipperAddr.zipCode : '';
      this.saveQuoteDataObj['sourceAddress']['city'] = this.shipperAddr.city ? this.shipperAddr.city:'';
      this.saveQuoteDataObj['sourceAddress']['state'] = this.shipperAddr.state ? this.shipperAddr.state : '';
      this.saveQuoteDataObj['sourceAddress']['country'] = this.shipperAddr.country ? this.shipperAddr.country : '';
      this.saveQuoteDataObj['sourceAddress']['addrLine1'] = this.shipperAddr.addrLine1 ? this.shipperAddr.addrLine1.split(',')[0] : '';
      // this.saveQuoteDataObj['sourceAddress']['addrLine2'] = this.shipperAddr.addrLine2 ? this.shipperAddr.addrLine2 : '';
      this.saveQuoteDataObj['sourceAddress']['addrLine2'] = '';
      this.saveQuoteDataObj['sourceAddress']['company'] = this.shipperAddr.company ? this.shipperAddr.company : '';
      this.saveQuoteDataObj['sourceAddress']['email'] = this.shipperAddr.email ? this.shipperAddr.email : '';
      this.saveQuoteDataObj['sourceAddress']['phoneNumber'] = this.shipperAddr.phoneNumber ? this.shipperAddr.phoneNumber : '';
      this.saveQuoteDataObj['sourceAddress']['latitude'] = this.shipperAddr.latitude ? this.shipperAddr.latitude : '';
      this.saveQuoteDataObj['sourceAddress']['longitude'] = this.shipperAddr.longitude ? this.shipperAddr.longitude : '';
      this.saveQuoteDataObj['sourceAddress']['firstName'] = this.shipperAddr.firstName ? this.shipperAddr.firstName : '';
      this.saveQuoteDataObj['sourceAddress']['lastName'] = this.shipperAddr.lastName ? this.shipperAddr.lastName : '';

      // this.saveQuoteDataObj['destinationAddress']['fullAddr'] = this.consigneeAddr.addrLine1 ? this.consigneeAddr.addrLine1 : '';
      this.consigneeFullAddr = this.consigneeAddr.fullAddr ? this.consigneeAddr.fullAddr : '';
      this.saveQuoteDataObj['destinationAddress']['zipCode'] = this.consigneeAddr.zipCode ? this.consigneeAddr.zipCode : '';
      this.saveQuoteDataObj['destinationAddress']['city'] = this.consigneeAddr.city ? this.consigneeAddr.city : '';
      this.saveQuoteDataObj['destinationAddress']['state'] = this.consigneeAddr.state ? this.consigneeAddr.state : '';
      this.saveQuoteDataObj['destinationAddress']['country'] = this.consigneeAddr.country ? this.consigneeAddr.country : '';
      this.saveQuoteDataObj['destinationAddress']['addrLine1'] = this.consigneeAddr.addrLine1 ? this.consigneeAddr.addrLine1.split(',')[0] : '';
      // this.saveQuoteDataObj['destinationAddress']['addrLine2'] = this.consigneeAddr.addrLine2 ? this.consigneeAddr.addrLine2 : '';
      this.saveQuoteDataObj['destinationAddress']['addrLine2'] = '';
      this.saveQuoteDataObj['destinationAddress']['latitude'] = this.consigneeAddr.latitude ? this.consigneeAddr.latitude : '';
      this.saveQuoteDataObj['destinationAddress']['longitude'] = this.consigneeAddr.longitude ? this.consigneeAddr.longitude : '';
      this.saveQuoteDataObj['destinationAddress']['company'] = this.consigneeAddr.company ? this.consigneeAddr.company : '';
      this.saveQuoteDataObj['destinationAddress']['email'] = this.consigneeAddr.email ? this.consigneeAddr.email : '';
      this.saveQuoteDataObj['destinationAddress']['phoneNumber'] = this.consigneeAddr.phoneNumber ? this.consigneeAddr.phoneNumber : '';
      this.saveQuoteDataObj['destinationAddress']['firstName'] = this.consigneeAddr.firstName ? this.consigneeAddr.firstName : '';
      this.saveQuoteDataObj['destinationAddress']['lastName'] = this.consigneeAddr.lastName ? this.consigneeAddr.lastName : '';
      this.saveQuoteDataObj['customerId'] = this.customerId;


      if (this.manulGoogleshipperAddr == false) {
        if (this.shipperAddr.addrLine1 != '' || null) {
          // this.isAddressBookShipper = true;
          this.detailsForm.patchValue({
            shipperAddressBook: true
          });
          this.clickEventShipper();
          this.shearchaddress = true;
          this.detailsForm.patchValue({
            shipperAddress: this.shipperAddr.fullAddr ? this.shipperAddr.fullAddr : '',
            // consigneeAddress: this.consigneeAddr.addrLine1 ? this.consigneeAddr.addrLine1 : '',
          });
        }
      }
      if (this.manulGoogleConsigneeAddr == false) {
        if (this.consigneeAddr.addrLine1 != '' || null) {
          // this.isAddressBookConsignee = true;
          this.detailsForm.patchValue({
            consigneeAddressBook: true
          });
          this.clickEventConsignee();
          this.shearchaddress2 = true;
          this.detailsForm.patchValue({
            // shipperAddress: this.shipperAddr.addrLine1 ? this.shipperAddr.addrLine1 : '',
            consigneeAddress: this.consigneeAddr.fullAddr ? this.consigneeAddr.fullAddr : '',
          });
        }
      }}
    }).catch((error) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      //this.toastr.error(error.message,'Something went wrong 1');
    })

  }

  switchAddress(type: string) {
    if (type === 'shipper') {
        // Swap the values and update the form correctly
        const tempShipper = this.shipperFullAddr;
        this.shipperFullAddr = this.consigneeFullAddr;
        this.consigneeFullAddr = tempShipper;

        // Immediately update the form values to reflect changes in UI
        this.detailsForm.patchValue({
            shipperAddress: this.shipperFullAddr,
            consigneeAddress: this.consigneeFullAddr
        });

        console.log(this.shipperFullAddr, "Shipper Address Switched");

    } else if (type === 'consignee') {
        // Swap values and update the form
        const tempConsignee = this.consigneeFullAddr;
        this.consigneeFullAddr = this.shipperFullAddr;
        this.shipperFullAddr = tempConsignee;

        // Immediately update the form values
        this.detailsForm.patchValue({
            shipperAddress: this.shipperFullAddr,
            consigneeAddress: this.consigneeFullAddr
        });

        console.log(this.consigneeFullAddr, "Consignee Address Switched");
    }
} 



  addHazmatFormGroup(): FormGroup {
    return this.formBuilder.group({
      unNumType: ['UN'],
      unNumber: ['', Validators.required],
      properShippingName: [''],
      hazardClass: [''],
      packageGroup: [''],
      hazardPackagingType: ['',Validators.required],
    });
  }

  addHazMatDetailRow(parentIndex: number): void {
    const hazMatDetailsArray = this.hazMatDetailArray(parentIndex);
    hazMatDetailsArray.push(this.addHazmatFormGroup());
  }

  hazMatDetailArray(parentIndex: number): FormArray {
    const inputFieldsArray = this.shipmentItemsForm.get('inputFields') as FormArray;
    const parentGroup = inputFieldsArray.at(parentIndex) as FormGroup;
    return parentGroup.get('hazMatDetails') as FormArray;
  }

  removeHazMatDetailRow(parentIndex: number, childIndex: number): void {
    const hazMatDetailsArray = this.hazMatDetailArray(parentIndex);
    if (hazMatDetailsArray.length > 1) {
      hazMatDetailsArray.removeAt(childIndex);
    }
  }
  
  

  addMeasureMentFormGroup(): FormGroup {
    return this.formBuilder.group({
      packageType: ['', [Validators.required]],
      pieceCount: ['', [Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
      length: ['', [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      width: ['', [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      height: ['', [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      weight: ['', [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      shipmentvalue: ['', [Validators.required, Validators.min(0.1), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
      description:['',
        [
          Validators.required,
          Validators.maxLength(30)
        ]
      ],
      class: [''],
      nmfc: [''],
      stack: [false],
      hazmet: [this.defaultHazmat],
      hazMatDetails:this.formBuilder.array([])

    });
  }

  onAddRow(): void {
    this.densityCounter += 1;
    (<FormArray>this.shipmentItemsForm.get('inputFields')).push(
      this.addMeasureMentFormGroup()
    );
    this.showHideHazmatOptions(this.densityCounter, this.defaultHazmat);
  }


  
  saveShipmentItems() {
    let arrayShipmentItems = [];
    console.log(this.shipmentItemsForm.value.inputFields,"shipmentItems")
    this.shipmentItemsForm.value.inputFields.map((data) => {
      

      if (!this.shipmentItemsForm.valid) {
        this.shipmentItemsForm.markAllAsTouched();
        if (data.height == null || data.height <= 0) {
          this.toastr.error('Please enter valid height', '', { timeOut: 10000 });
        } else if (data.length == null || data.length <= 0) {
          this.toastr.error('Please enter valid length', '', { timeOut: 10000 });
        } else if (data.weight == null || data.weight <= 0) {
          this.toastr.error('Please enter valid weight', '', { timeOut: 10000 });
        } else if (data.width == null || data.width <= 0) {
          this.toastr.error('Please enter valid width', '', { timeOut: 10000 });
        } else if (data.pieceCount == null || data.pieceCount <= 0) {
          this.toastr.error('Please enter valid quantity', '', { timeOut: 10000 });
        } 
        else if (data.shipmentvalue == null || data.shipmentvalue <= 0) {
          this.toastr.error('Please enter valid shipment value', '', { timeOut: 10000 });
        }
        else if (data.packageType == null || data.packageType == '') {
          this.toastr.error('Please select valid Package Type', '', { timeOut: 10000 });
        } else if (data.description == null || data.description == '' || data.description.length > 30) {
          this.toastr.error('Please enter valid commodity description', '', { timeOut: 10000 });
        }
      } else {


        // let freightRangeValue = this.orderIsWithInCanada ? null : (data.class ? data.class : '50');
        let objItem = {
          qty: data.pieceCount.toString(),
          totalWeight: data.weight.toString(),
          length: data.length.toString(),
          breadth: data.width.toString(),
          height: data.height.toString(),
          unit: this.measurementType == 'metric' ? 'cm' : 'inch',
          wghtUnit: this.measurementType == 'metric' ? 'kg' : 'lbs',

          packaging: this.selectedPackagingTypeStyleCode == 'courier' ? null : data.packageType,
          styleCode: this.selectedPackagingTypeStyleCode,
          quoteItemId: null,
          showFreight: true,
          // freightClass: !this.orderIsWithInCanada ? this.shipmentModeCourier ? null : this.getfrieghtClassId(freightRangeValue) : null,
          // freightRange: !this.orderIsWithInCanada ? this.shipmentModeCourier ? null : freightRangeValue : null,
          freightClass: this.shipmentModeCourier ? null : this.getfrieghtClassId(data.class),
          freightRange: this.shipmentModeCourier ? null : data.class,
          lengthType: this.measurementType,
          shipmentItemValue: data.shipmentvalue,
          shipmentItemValueCurrency: this.setcurrency[0],
          stackable: data.stack == true ? true : false,
          description:data.description,
          hazMat: data.hazmet == true ? true : false,
          hazardClass: data.hazmatHazardClass,
          unNumType: data.hazmatNu,
          packageGroup: data.hazmatPackageGroup,
          hazMatDetails:data.hazmet ?data.hazMatDetails:null,
          hazardPackagingType: data.hazardPackagingType,
          properShippingName: data.hazmatProperShippingName,
          unNumber: data.hazmatUnNumber,
          displayOrder: this.displayOrder++,
          crsPackageId:
            this.selectedPackagingTypeStyleCode == 'courier'
              ? data.packageType
              : null,
        };

        arrayShipmentItems.push(objItem);
      }
    });
    this.saveQuoteDataObj['items'] = [...arrayShipmentItems];
    
  }
  ngOnDestroy(): void {
    this.modalService.dismissAll();
  
  }

  populateData(input, selectCpFromDropDown?) {

    if (selectCpFromDropDown) {
      let i = this.shipmentItemsForm.get('inputFields')['controls'].length;
      if (input.length >= 1) {
        (<FormArray>this.shipmentItemsForm.get('inputFields')).removeAt(i - 1);
      }
    }

    const formArray = this.shipmentItemsForm.get('inputFields') as FormArray;
    this.densityCounter = input.length - 1;

    input.forEach((dimention,i) => {
      formArray.insert(i,
        this.formBuilder.group({
          packageType: [dimention.shipment_type_id, [Validators.required]],
          pieceCount: ['', [Validators.required, Validators.min(1), Validators.max(99999), Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),],],
          length: [dimention.package_dimension.length, [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          width: [dimention.package_dimension.width, [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          height: [dimention.package_dimension.height, [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          weight: [dimention.package_dimension.weight, [Validators.required, Validators.min(0.1), Validators.max(99999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),],],
          shipmentvalue: ['',
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(999999),
              Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),
            ]
          ],
          description: ['',
            [
              Validators.required,
              Validators.maxLength(30)
            ]
          ],
          class: [''],
          nmfc: [''],
          stack: [false],
          hazmet: [this.defaultHazmat],
          hazmatNu: [''],
          hazmatUnNumber: [''],
          hazmatProperShippingName: [''],
          hazmatHazardClass: [''],
          hazmatPackageGroup: [''],
          hazardPackagingType: [''],
        })
      );
    });
    this.shipmentItemsForm.setControl('inputFields', formArray);
  }

  shipmentItemsData(input) {
    const formArray = new FormArray([]);
    input.forEach((dimention) => {
  const hazMatDetailsFormArray=new FormArray([]);
  if(dimention.hazMatDetails){
    dimention.hazMatDetails.forEach((element,i) => {
      hazMatDetailsFormArray.push(
        this.formBuilder.group({
          hazMatItemId: [element.hazMatItemId || ''],
          unNumType: [element.unNumType || ''],
          unNumber: [element.unNumber || ''],
          properShippingName: [element.properShippingName || ''],
          hazardClass: [element.hazardClass || ''],
          packageGroup: [element.packageGroup || ''],
          hazardPackagingType: [element.hazardPackagingType || ''],
          quoteItemId: [element.quoteItemId || ''],
          hazMat: [element.hazMat || false],
        })
      );
    });
  }

      formArray.push(
        this.formBuilder.group({
          packageType: [
            dimention.crsPackageId
              ? dimention.crsPackageId
              : dimention.packaging,
          ],
          pieceCount: [
            dimention.qty,
            [
              Validators.required,
              Validators.min(1),
              Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          length: [
            dimention.length,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          width: [
            dimention.breadth,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          height: [
            dimention.height,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          weight: [
            dimention.totalWeight,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(99999),
              Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          description: [
            dimention.description,
            [
              Validators.required,
              Validators.maxLength(30)
            ]
          ],
          shipmentvalue: [
            dimention.shipmentItemValue,
            [
              Validators.required,
              Validators.min(0.1),
              Validators.max(999999),
              Validators.pattern(/^[0-9]{1,11}(?:\.[0-9]{1,2})?$/),
            ],
          ],
          class: [dimention.freightRange],
          nmfc: [dimention.nmfc],
          stack: [dimention.stackable],
          hazmet: [dimention.HazMat],
          hazMatDetails:hazMatDetailsFormArray
          // hazmatNu: [dimention.unNumType],
          // hazmatUnNumber: [dimention.unNumber],
          // hazmatProperShippingName: [dimention.properShippingName],
          // hazmatHazardClass: [dimention.hazardClass],
          // hazmatPackageGroup: [dimention.packageGroup],
          // hazardPackagingType: [dimention.hazardPackagingType],
        })
      );
    });
    this.shipmentItemsForm.setControl('inputFields', formArray);
    var a = 0;
    input.filter((element) => {
      this.densityCounter = input.length - 1;
      if (element.crsPackageId != null) {
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('class').patchValue('');
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('class').reset();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('class').patchValue('');
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('nmfc').reset();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('stack').reset();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('hazmet').reset();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('class').disable();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('nmfc').disable();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('stack').disable();
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('hazmet').disable();
        a++;
      } else {
        ((this.shipmentItemsForm.get('inputFields') as FormArray).at(a) as FormGroup).get('class').patchValue(element.freightRange);
        if (element.HazMat == true) {
          this.showHideHazmatOptions(a, true, "patch");
          // this.totalClass = element.freightClass;
          a++;
        } else {
          this.showHideHazmatOptions(a, false);
          a++;
        }
      }
    });
  }

  setExistingData(input) { }
  hazmatSpecificClass: string = '';
  showHideHazmatOptionsIndexArr = [];
  // showHideHazmatOptions(i, ele) {
  //   if (ele) {
  //     // this.shipmentItemsForm.controls.inputFields['controls'][i].controls.hazmatPackageGroup.setValidators([Validators.required]);
  //     // this.shipmentItemsForm.controls.inputFields['controls'][i].controls.hazardPackagingType.setValidators([Validators.required]);
  //     this.showHideHazmatOptionsIndexArr.push(i);
  //   } else {
  //     // this.shipmentItemsForm.controls.inputFields['controls'][i].controls.hazmatPackageGroup.setValidators(null);
  //     // this.shipmentItemsForm.controls.inputFields['controls'][i].controls.hazmatPackageGroup.updateValueAndValidity();
  //     // this.shipmentItemsForm.controls.inputFields['controls'][i].controls.hazardPackagingType.setValidators(null);
  //     // this.shipmentItemsForm.controls.inputFields['controls'][i].controls.hazardPackagingType.updateValueAndValidity();
  //     let tempArr = this.showHideHazmatOptionsIndexArr.filter((data) => {
  //       return i !== data;
  //     });
  //     this.showHideHazmatOptionsIndexArr = tempArr;
  //   }
  // }


  showHideHazmatOptions(i: number, isChecked: boolean, type?) {
    const inputFields = this.shipmentItemsForm.get('inputFields') as FormArray;
    const currentFieldGroup = inputFields.at(i) as FormGroup;
  
    if (isChecked) {
      
      // Set the hazMat field to true
      // Add validators to hazardPackagingType and unNumber in hazMatDetails array
      const hazMatDetails = currentFieldGroup.get('hazMatDetails') as FormArray;

      if(!type){
        this.addHazMatDetailRow(i)

      }
      console.log(hazMatDetails,"hamat")

      hazMatDetails.controls.forEach((group: any, index) => {

        group.get('hazardPackagingType').setValidators([Validators.required]);
        group.get('unNumber').setValidators([Validators.required]);
  
        group.get('hazardPackagingType').updateValueAndValidity();
        group.get('unNumber').updateValueAndValidity();
      });
  
      // Track selected hazmat indices
      if (!this.showHideHazmatOptionsIndexArr.includes(i)) {
        this.showHideHazmatOptionsIndexArr.push(i);
      }
    } else {
      

      currentFieldGroup.get('hazmet').setValue(false);
  
      const hazMatDetails = currentFieldGroup.get('hazMatDetails') as FormArray;

  
      hazMatDetails.controls.forEach((group: any,index) => {
        group.get('hazardPackagingType').clearValidators();
        group.get('unNumber').clearValidators();
  
        group.get('hazardPackagingType').setValue('');
        group.get('unNumber').setValue('');
  
        this.removeHazMatDetailRow(i,index);

        group.get('hazardPackagingType').updateValueAndValidity();
        group.get('unNumber').updateValueAndValidity();
      });
  
      // Remove index from the tracking array
      this.showHideHazmatOptionsIndexArr = this.showHideHazmatOptionsIndexArr.filter(
        (index) => index !== i
      );
    }
  }

  

  handleAddressChange(e) {
    //getting the zipcode

    this.showDetailedAddressError = false;
    this.showShipperWarning = false;
    this.address_components = e.address_components;
    this.postalValid = false;
    this.getzipcodestart(e.formatted_address, e.address_components, e.geometry.location, 'start');
    this.commanService.shipperZIPCode = this.extractZipCode(this.shipperFullAddr);
    if (this.commanService.shipperZIPCode == null) {
      this.showDetailedAddressError = true;
    } else if (this.commanService.shipperZIPCode != null) {
      this.showDetailedAddressError = false;
    }
    e.address_components['place_id'] = e.place_id;
    this.callcurrency(e.address_components, 'shipper');
    this.geometry = e.geometry.location;
    var latitude = e.geometry.location.lat();
    var longitude = e.geometry.location.lng();

    this.origin = { lat: latitude, lng: longitude };
    if (!this.latlng.length) {
      this.latlng.push([latitude, longitude]);
    } else {
      this.latlng[0] = [latitude, longitude];
    }


    this.getResultFromMapService(
      { placeId: e.place_id, fields: ['name', 'business_status'] },
      'start'
    );
  }

  clickEventShipper() {
    this.isAddressBookShipper = !this.isAddressBookShipper;
  }

  clickEventConsignee() {
    this.isAddressBookConsignee = !this.isAddressBookConsignee;
  }


  resetAddShipmentItemForm(i) {

    if (this.shipmentItemsForm.get('inputFields')['controls'].length > 1) {
      let tempArr = this.showHideHazmatOptionsIndexArr.filter((data) => {
        return i !== data;
      });
      this.showHideHazmatOptionsIndexArr = tempArr;
      this.densityCounter -= 1;
      (<FormArray>this.shipmentItemsForm.get('inputFields')).removeAt(i);
    } else {
      let tempArr = this.showHideHazmatOptionsIndexArr.filter((data) => {
        return i !== data;
      });
      this.showHideHazmatOptionsIndexArr = tempArr;
      this.densityCounter = 0;
      this.shipmentItemsForm.reset();
      this.totalOfQuantity = 0;
      this.totalOfWeight = 0.0;
      this.totalOfShipmentValue = 0.0;

    }
  }

  ShowHideButton() {
    this.showMainContent = this.showMainContent ? false : true;
  }

  callcurrency(address, action) {
    for (let key in address) {
      for (let endkey in address[key]) {
        for (let mainkey in address[key][endkey]) {
          if (address[key][endkey].includes('country') == true) {
            if (action == 'shipper') {
              this.countryShipperString = address[key]['short_name'];
            } else {
              this.countryConsigneeString = address[key]['short_name'];
            }
          }
        }
      }
    }
    this.displayCurrency();
  }

  shipperFullAddr: string = '';
  consigneeFullAddr: string = '';
  getzipcodestart(formatted_address, address, geometry, action) {
    this.postalValid = false;
    let addrLine1 = ''; // Temporary variable to build addrLine1
  
    // Helper variables to track specific components
    let streetNumber = '';
    let subPremise = '';
    let route = '';

    console.log(address)
    console.log(this.saveQuoteDataObj)
    if (geometry.lat()) {
      if (action === 'start') {
        this.saveQuoteDataObj['sourceAddress']['latitude'] = geometry.lat();
        this.saveQuoteDataObj['sourceAddress']['longitude'] = geometry.lng();
        // this.saveQuoteDataObj['sourceAddress']['fullAddr'] = formatted_address;
        this.shipperFullAddr = formatted_address;
      } else {
        this.saveQuoteDataObj['destinationAddress']['latitude'] = geometry.lat();
        this.saveQuoteDataObj['destinationAddress']['longitude'] = geometry.lng();
        // this.saveQuoteDataObj['destinationAddress']['fullAddr'] = formatted_address;
        this.consigneeFullAddr = formatted_address;
      }
    }

    if (action === 'start') {
      // this.saveQuoteDataObj['sourceAddress']['fullAddr'] = formatted_address;
      this.shipperFullAddr = formatted_address;
      this.commanService.shipperZIPCode = this.shipperFullAddr
    } else {
      // this.saveQuoteDataObj['destinationAddress']['fullAddr'] = formatted_address;
      this.consigneeFullAddr = formatted_address;
      this.commanService.consigneeZIPCode = this.consigneeFullAddr
    }

    if (action === 'start') {
      this.saveQuoteDataObj['sourceAddress']['addrLine1'] = '';
      this.saveQuoteDataObj['sourceAddress']['addrLine2'] = '';
    } else {
      this.saveQuoteDataObj['destinationAddress']['addrLine1'] = '';
      this.saveQuoteDataObj['destinationAddress']['addrLine2'] = '';
    }

    let isCity=true;
    let cityArrayObj=[]
    for (let key in address) {
      if (address[key]['types'][0] == 'country') {
        if (action == 'start') {
          this.saveQuoteDataObj['sourceAddress']['country'] =
            address[key]['short_name'];
          this.selectedShipperAddressCountry = address[key]['short_name']
          // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();
        } else {
          this.saveQuoteDataObj['destinationAddress']['country'] =
            address[key]['short_name'];
          this.selectedCosigneeAddressCountry = address[key]['short_name']
          // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();
        }
      }
      //fetching the city name
 
      // if(address[key]['types'].includes('locality') || address[key]['types'].includes('sublocality') || address[key]['types'].includes('neighborhood')){
      //   cityArrayObj.push(address[key]['short_name'])
      // }
      if (
        address[key]["types"][0] == "locality" ||
        address[key]["types"][0] == "sublocality_level_1" ||
        address[key]["types"][0] == "neighborhood"
      ) {
        if (action === "start") {
          this.saveQuoteDataObj['sourceAddress']['city']=
              address[key]["short_name"]
        } else {
          this.saveQuoteDataObj['destinationAddress']['city']=
              address[key]["short_name"]
        }
      }
      // if (
      //   address[key]['types'][2] == 'political' &&
      //   address[key]['types'][0] == 'sublocality_level_1' &&
      //   address[key]['types'][1] == 'sublocality'
      // ) {
      //   isCity=false
      //   if (action == 'start') {
      //     this.saveQuoteDataObj['sourceAddress']['city'] =
      //       address[key]['short_name'];
      //   } else {
      //     this.saveQuoteDataObj['destinationAddress']['city'] =
      //       address[key]['short_name'];
      //   }
      // }else if(isCity){
      //   if(address[key]['types'][0]=='locality' && address[key]['types'][1]=='political'){
      //     if (action == 'start') {
      //       this.saveQuoteDataObj['sourceAddress']['city'] =
      //         address[key]['short_name'];
      //     } else {
      //       this.saveQuoteDataObj['destinationAddress']['city'] =
      //         address[key]['short_name'];
      //     }
      //   }
      // }


      if (address[key]['types'][0] == 'administrative_area_level_1') {
        if (action == 'start') {
          this.saveQuoteDataObj['sourceAddress']['state'] =
            address[key]['short_name'];
        } else {
          this.saveQuoteDataObj['destinationAddress']['state'] =
            address[key]['short_name'];
        }
      }

     
      if (address[key]['types'][0] == 'postal_code') {
        if (action == 'start') {
          this.saveQuoteDataObj['sourceAddress']['zipCode'] =
            address[key]['long_name'];

        } else {
          this.saveQuoteDataObj['destinationAddress']['zipCode'] =
            address[key]['long_name'];

        }
        this.postalValid = true;
      }
      // if (action == 'start') {
      //   address[key]['types'].forEach((ele, ind) => {
      //     if (ele == 'postal_code') {
      //       this.commanService.shipperZIPCode = address[key]['long_name']
      //       this.saveQuoteDataObj['sourceAddress']['zipCode'] = address[key]['long_name']
      //     }
      //   })
      // } else {
      //   address[key]['types'].forEach((ele, ind) => {
      //     if (ele == 'postal_code') {
      //       this.commanService.shipperZIPCode = address[key]['long_name']
      //       this.saveQuoteDataObj['destinationAddress']['zipCode'] = address[key]['long_name']
      //     }
      //   })
      // }

      const type = address[key]['types'][0];
      if (type === 'street_number') {
        streetNumber = address[key]['short_name'];
      } else if (type === 'subpremise') {
        subPremise = address[key]['short_name'];
      } else if (type === 'route') {
        route = address[key]['short_name'];
      }

      if (action === 'start') {
        this.saveQuoteDataObj['sourceAddress']['addrLine2'] = '';
        let stepStart = 0;
        // if (!this.saveQuoteDataObj['sourceAddress']['addrLine1'] && (address[key]['types'][0] === 'subpremise' || address[key]['types'][0] === 'street_number')) {
        //   stepStart++;
        //   this.saveQuoteDataObj['sourceAddress']['addrLine1'] = address[key]['short_name'];

        // }

        // if (address[key]['types'][0] === 'route') {
        //   if(!this.saveQuoteDataObj['sourceAddress']['addrLine1']){
 
        //     this.saveQuoteDataObj['sourceAddress']['addrLine1'] = address[key]['short_name'];
        //   }else{
 
        //     this.saveQuoteDataObj['sourceAddress']['addrLine1'] = this.saveQuoteDataObj['sourceAddress']['addrLine1'] +' '+ address[key]['short_name'];
        //   }
        // }

        // if (!this.saveQuoteDataObj['sourceAddress']['addrLine1'] && !stepStart) {
        //   // this.showDetailedAddressError = true;
        //   return;
        // }
      } else {
        this.saveQuoteDataObj['destinationAddress']['addrLine2'] = '';
        // let stepStartConsignee = 0;
        // if (!this.saveQuoteDataObj['destinationAddress']['addrLine1'] && (address[key]['types'][0] === 'subpremise' || address[key]['types'][0] === 'street_number')) {
        //   stepStartConsignee++;
        //   this.saveQuoteDataObj['destinationAddress']['addrLine1'] = address[key]['short_name'];
        // }

        // if (address[key]['types'][0] === 'route') {
        //   if(!this.saveQuoteDataObj['destinationAddress']['addrLine1']){
         
        //     this.saveQuoteDataObj['destinationAddress']['addrLine1'] = address[key]['short_name'];
        //   }else{
           
        //     this.saveQuoteDataObj['destinationAddress']['addrLine1'] = this.saveQuoteDataObj['destinationAddress']['addrLine1'] +' '+ address[key]['short_name'];
        //   }
        // }
        
      }
    }

    if (subPremise) {
      addrLine1 = `${streetNumber} ${route} ${subPremise}`.trim();
    } else {
      addrLine1 = `${streetNumber} ${route}`.trim();
    }
    if(action === 'start'){
       this.saveQuoteDataObj['sourceAddress']['addrLine1'] = addrLine1;
    }
    else{
      this.saveQuoteDataObj['destinationAddress']['addrLine1'] = addrLine1;
    }
    
    // cityArrayObj.forEach((cityName,indexOfCity)=>{
    //   let fullAddr=formatted_address.toLowerCase();
    //   if(action=='start'){
    //     this.saveQuoteDataObj['sourceAddress']['city']=''
    //   }else{
    //     this.saveQuoteDataObj['destinationAddress']['city']=''
    //   }
    //   if(fullAddr.includes(cityName.toLowerCase())){
    //     if(action=='start'){
    //       this.saveQuoteDataObj['sourceAddress']['city']=cityName
    //     }else{
    //       this.saveQuoteDataObj['destinationAddress']['city']=cityName
    //     }
    //   }
    // })
    if (action == 'start' && !this.postalValid) {
      this.saveQuoteDataObj['sourceAddress']['zipCode'] = "";
      // this.showDetailedAddressError = true;
      this.toastr.error('Please enter zipcode', '', {
        timeOut: 10000,
      });
    } else {
      // this.showDetailedAddressError = false;
    }

    if (action == 'end' && !this.postalValid) {
      this.saveQuoteDataObj['destinationAddress']['zipCode'] = "";
      this.toastr.error('Please enter zipcode', '', {
        timeOut: 10000,
      });
      // this.showDetailedEndAddressError = true;
    } else {
      // this.showDetailedEndAddressError = false;
    }
    console.log(this.saveQuoteDataObj)
  }

  handleConsignee(e) {
    this.selectedCosigneeAddressCountry = e['address_components'] && e['address_components'][4] ? e['address_components'][4]['short_name'] : undefined;
    e.address_components.forEach((element) => {
      if (element['types'][0] === 'country') {
        this.selectedCosigneeAddressCountry = element.short_name
      }
    })
    // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();
    this.showDetailedEndAddressError = false;
    this.showConsigneeWarning = false;

    var latitude = e.geometry.location.lat();
    var longitude = e.geometry.location.lng();
    this.destination = { lat: latitude, lng: longitude };

    if (!this.latlng.length) {
      this.latlng[0] = [];
      this.latlng[1] = [latitude, longitude];
    } else if (this.latlng.length >= 1) {
      this.latlng[1] = [latitude, longitude];
    }
    this.getzipcodestart(
      e.formatted_address,
      e.address_components,
      e.geometry.location,
      'end'
    );
    this.commanService.consigneeZIPCode = this.extractZipCode(this.consigneeFullAddr);
    if (this.commanService.consigneeZIPCode == null) {
      this.showDetailedEndAddressError = true;
    } else if (this.commanService.consigneeZIPCode != null) {
      this.showDetailedEndAddressError = false;
    }
    this.callcurrency(e.address_components, 'consignee');

    //Get Business Name
    this.getResultFromMapService(
      { placeId: e.place_id, fields: ['name', 'business_status'] },
      'end'
    );
  }

  //************************************************** Package Mode and Package Type ************************************************/

  getshipmentData() {
    this.shipmodeNameArray = []
    let url = ServerURLS.getShipmentModeData.action;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      // this.shipmentData = result;
      this.customerDetailsByid = result;
      if (this.shipAgainQuoteID !== null) {
        this.getQuoteDetailsById(this.shipAgainQuoteID);
      }
    }).catch((error) => {
      if (error['status'] === 401) {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    });
  }

  //************************************************** End Package Mode and Package Type ************************************************/
  lastName:any
  // **************************************************Customer all details Api ***********************************************************
  customerAllDetails(customerId: any) {
    this.openModel();
    let url = ServerURLS.getCustomerById.action + "?customerId=" + this.customerId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.customerAllData = res;
      this.customerName = res.general_details.customer_name;
      this.availableCreditLimitInCAD=parseFloat(res.general_details.available_credit_limit).toFixed(2);
      this.availableCreditLimitInUSD=parseFloat(res.general_details.available_credit_limit_usd).toFixed(2);
      this.isCreditDisabled=res.general_details.is_credit_disabled;
      this.filterData = res.contact_information.map((data) => {
        if (data.is_primary_contact === true) {
          this.firstName = data.first_name;
          this.lastName = data.last_name || ''; // Set to empty string if undefined
          this.emailAdd = data.email_address;
          this.phoneNumber = data.phone_number;
        }
      })

      this.businessId = res.business_type_id;
      this.serviceType = res.services_type
      this.packageType = res.package_type
      let data = []
      this.serviceType.map((element1) => {
        this.customerDetailsByid.map((element2) => {
          if (element2.shippingModeId == element1.services_id) {
            data.push(element2);
          }
        })
      })
      this.shipmentData = data;
      
      this.getLocationType(customerId, res.business_type_id)
      this.modalService.dismissAll();
    }).catch((err: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.modalService.dismissAll();
      this.toastr.error("something went wrong!0000000")

    })

  }
  // ************************************************** End Customer all details Api ***********************************************************

  getQuoteDetailsById(quoteId) {

    this.openModel();
    let url = ServerURLS.getQuoteDetailsById.action + '?quoteId=' + quoteId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((details: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.details = details;
      this.shipmentCode=details.shipmentMode
      this.customerId = this.details.customerId
      this.selectedCosigneeAddressCountry = details['consigneeAddress'].country;
      this.selectedShipperAddressCountry = details['shipperAddress'].country;
      // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
      this.customerAllDetails(this.customerId);
      let pickupDate = details.pickupDate.split("-")
      this.selectedDate = this.selectedDate = { year: parseInt(pickupDate[0]), month: parseInt(pickupDate[1]), day: parseInt(pickupDate[2]) };
      this.saveQuoteDataObj['customerId'] = this.customerId;
      this.isShipmentModeSelected = true;
      this.packagingTypeId = details.shipmentSubPackageCode
      this.shpmtModeId = details.shipmentModeId,
      this.saveQuoteDataObj['poNumber']=details.poNumber,
      this.saveQuoteDataObj['referenceNumber']=details.referenceNumber,
      this.saveQuoteDataObj['shipmentMode'] = this.shpmtModeId;
      this.saveQuoteDataObj['shpmtPackagingTypeId'] = details.packagingId;
      this.saveQuoteDataObj['shipmentSubPackageCode'] = details.shipmentSubPackageCode;
      this.saveQuoteDataObj['sourceAddress']['email'] = details.shipperAddress.email;
      this.saveQuoteDataObj['sourceAddress']['firstName'] = details.shipperAddress.firstName;
      this.saveQuoteDataObj['sourceAddress']['lastName'] = details.shipperAddress.lastName;
      this.saveQuoteDataObj['sourceAddress']['phoneNumber'] = details.shipperAddress.phoneNumber;
      this.saveQuoteDataObj['destinationAddress']['email'] = details.consigneeAddress.email;
      this.saveQuoteDataObj['destinationAddress']['phoneNumber'] = details.consigneeAddress.phoneNumber;
      this.saveQuoteDataObj['destinationAddress']['firstName'] = details.consigneeAddress.firstName;
      this.saveQuoteDataObj['destinationAddress']['lastName'] = details.consigneeAddress.lastName;




      this.shipmentName = details.shipmentMode;
      this.shipmentStyleCode = details.shipmentModeStyleCode;
      let data = this.customerDetailsByid.filter((data => data.shippingModeId == this.details.shipmentModeId))
      this.shipmentData = data;


      this.customerDetailsByid.map((res) => {
        if (this.shipmentData[0].shippingModeId === res.shippingModeId) {

          if (res.shippingModeId === '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
            this.shipmentModeCourier = true;
            this.filterShipmentMode = res.packagingDetails;

          } else {
            this.shipmentModeCourier = false
            this.filterShipmentMode = res.packagingTypeDetails
          }
        }
      })

      if (details['customOrderId'] != null) {
        this.editOrderEnabled = true;
        this.editOrderNumber = details['customOrderId'];
        //  this.common.editableCustomOrderId = this.editOrderNumber;
      }
      
      let property = 'displayOrder';
      let direction = 1;
      this.details['items'].sort(function (a, b) {
        if (parseFloat(a[property]) < parseFloat(b[property])) {
          return -1 * direction;
        }
        else if (parseFloat(a[property]) > parseFloat(b[property])) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
      this.saveQuoteDataObj['items'] = this.details['items'];
      this.setUnitValue(this.details.items[0].lengthType)
      this.details['items'].forEach((item, index) => {
        this.totalClass = this.saveQuoteDataObj['items'][index][
          'freightRange'
        ] = item.freightClass;

        this.saveQuoteDataObj['items'][index]['HazMat'] = item.hazMat;
        if (item.hazMat) {
          this.showHideHazmatOptionsIndexArr = [];
          this.showHideHazmatOptionsIndexArr.push(item);
        }
        for (let key in this.freightDisplayDataObj) {
          if (
            this.freightDisplayDataObj[key]['freightType'] ==
            item.freightClass
          ) {
            this.saveQuoteDataObj['items'][index]['freightClass'] = key;
          }
        }
      });
      this.shipmentItemsData(this.saveQuoteDataObj['items']);
      this.addItemArray = this.saveQuoteDataObj['items'];

      this.selectedPackagingTypeStyleCode =
        this.details['shipmentModeStyleCode'];
      this.shipperAddress =
        this.details['shipperAddress'] &&
          this.details['shipperAddress']['company'] != null
          ? this.details['shipperAddress']['company'] +
          ',' +
          this.details['shipperAddress']['addrLine1'] +


          ',' +
          this.details['shipperAddress']['addrLine2'] +
          ',' +
          this.details['shipperAddress']['city'] +
          ',' +
          this.details['shipperAddress']['state'] +
          ',' +
          this.details['shipperAddress']['country']
          : '';

      this.consigneeAddress =
        this.details['consigneeAddress'] &&
          this.details['consigneeAddress']['company'] != null
          ? this.details['consigneeAddress']['company'] +
          ',' +
          this.details['consigneeAddress']['addrLine1'] +
          ',' +
          this.details['consigneeAddress']['addrLine2'] +
          ',' +
          this.details['consigneeAddress']['city'] +
          ',' +
          this.details['consigneeAddress']['state'] +
          ',' +
          this.details['consigneeAddress']['country']
          : '';
      this.resetGlobalCalculation(this.details['items']);
      if (details['shipperCompanyId']) {
        this.saveQuoteDataObj['shipperCompanyId'] =
          details['shipperCompanyId'];
      }
      if (details['consigneeCompanyId']) {
        this.saveQuoteDataObj['consigneeCompanyId'] =
          details['consigneeCompanyId'];
      }
      // this.saveQuoteDataObj['sourceAddress']['fullAddr'] = details['shipperAddress']['fullAddr'];
      this.shipperFullAddr = details['shipperAddress']['fullAddr'];
      this.saveQuoteDataObj['sourceAddress']['addrLine1'] =
        details['shipperAddress']['addrLine1'];
      this.saveQuoteDataObj['sourceAddress']['addrLine2'] =
        details['shipperAddress']['addrLine2'];
      this.saveQuoteDataObj['sourceAddress']['city'] =
        details['shipperAddress']['city'];
      this.countryShipperString = this.saveQuoteDataObj['sourceAddress'][
        'country'
      ] = details['shipperAddress']['country'];
      this.saveQuoteDataObj['sourceAddress']['state'] =
        details['shipperAddress']['state'];
      this.saveQuoteDataObj['sourceAddress']['company'] =
        details['shipperAddress']['company'];
      this.saveQuoteDataObj['sourceAddress']['zipCode'] =
        details['shipperAddress']['zipCode'];
      this.saveQuoteDataObj['sourceAddress']['latitude'] = parseFloat(
        details['shipperAddress']['latitude']
      );
      this.saveQuoteDataObj['sourceAddress']['longitude'] = parseFloat(
        details['shipperAddress']['longitude']
      );
      this.origin = {
        lat: parseFloat(details['shipperAddress']['latitude']),
        lng: parseFloat(details['shipperAddress']['longitude']),
      };
      // this.saveQuoteDataObj['destinationAddress']['fullAddr'] = details['consigneeAddress']['fullAddr'];
      this.consigneeFullAddr = details['consigneeAddress']['fullAddr'];
      this.saveQuoteDataObj['destinationAddress']['addrLine1'] =
        details['consigneeAddress']['addrLine1'];
      this.saveQuoteDataObj['destinationAddress']['addrLine2'] =
        details['consigneeAddress']['addrLine2'];
      this.saveQuoteDataObj['destinationAddress']['city'] =
        details['consigneeAddress']['city'];
      this.saveQuoteDataObj['destinationAddress']['state'] =
        details['consigneeAddress']['state'];
      this.saveQuoteDataObj['destinationAddress']['firstName'] =
        details['consigneeAddress']['firstName'];
      this.saveQuoteDataObj['destinationAddress']['lastName'] =
        details['consigneeAddress']['lastName'];
      this.countryConsigneeString = this.saveQuoteDataObj[
        'destinationAddress'
      ]['country'] = details['consigneeAddress']['country'];
      this.saveQuoteDataObj['destinationAddress']['company'] =
        details['consigneeAddress']['company'];
      this.saveQuoteDataObj['destinationAddress']['zipCode'] =
        details['consigneeAddress']['zipCode'];
      this.saveQuoteDataObj['destinationAddress']['latitude'] = parseFloat(
        details['consigneeAddress']['latitude']
      );
      this.saveQuoteDataObj['destinationAddress']['longitude'] = parseFloat(
        details['consigneeAddress']['longitude']
      );
      this.displayCurrency();
      this.destination = {
        lat: parseFloat(details['consigneeAddress']['latitude']),
        lng: parseFloat(details['consigneeAddress']['longitude']),
      };
      this.detailsForm.controls['locationTypeStart'].setValue(
        details['shipperAddress']['addrTypeId']
      );
      this.onOptionsSelected(details['shipperAddress']['addrTypeId']);
      this.detailsForm.controls['locationTypeEnd'].setValue(
        details['consigneeAddress']['addrTypeId']
      );
      this.onOptionsSelectedConsignee(
        details['consigneeAddress']['addrTypeId']
      );
      this.latlng[0] = [
        parseFloat(details['shipperAddress']['latitude']),
        parseFloat(details['shipperAddress']['longitude']),
      ];
      this.latlng[1] = [
        parseFloat(details['consigneeAddress']['latitude']),
        parseFloat(details['consigneeAddress']['longitude']),
      ];



      this.modalService.dismissAll();

    }).catch((error) => {

      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.modalService.dismissAll();
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      } else {
        // this.toastr.error('Something went wrong.', '', {
        //   timeOut: 50000,
        // });
        return;
      }
    })
  }

  default() { }

  shipmentTypeList(indexKey, shipmentDat) {
 console.log(shipmentDat,"shipmentTypeList")
 this.checkAccesserial();

 this.shipmentCode=shipmentDat.shipmentCode;
 this.setDefaultAccessoral(this.accessorialData['end']);
 this.detailsForm.controls['locationTypeEnd'].setValue('')
    if (shipmentDat.shippingModeId === '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
      let data = [];
      if (this.packageType) {
        this.customerDetailsByid.map((element) => {
          if (element.shippingModeId === shipmentDat.shippingModeId) {
            for (let i = 0; i < this.packageType.length; i++) {
              const element1 = this.packageType[i];
              const packagingDetails = element['packagingDetails'];
              let data1 = packagingDetails.filter(data => data.packagingId === element1.package_id);
              if (data1.length != 0) {
                data.push(data1[0])
              }
            }
          }
        })


      }
      this.crPkgType = data;

    } else {
      let data1 = [];
      if (this.packageType) {
        this.customerDetailsByid.map((element) => {
          if (element.shippingModeId === shipmentDat.shippingModeId) {
            element['packagingTypeDetails'].map((element) => {
              let data2 = this.packageType.filter(data => data.package_id === element.packagingId);
              if (data2.length !== 0) {
                data1.push(element);
              }
            })
          }
        })

        this.pkgType = data1;
      }

    }


    this.isShipmentModeSelected = true;
    if (shipmentDat['shippingModeId'] === '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
      this.filterShipmentMode = this.crPkgType;
      this.shipmentModeCourier = true;
      this.shpmtModeId = this.saveQuoteDataObj['shipmentMode'] = shipmentDat['shippingModeId'];
    } else {
      this.filterShipmentMode = this.pkgType;
      this.shipmentModeCourier = false;
      this.shpmtModeId = this.saveQuoteDataObj['shipmentMode'] = shipmentDat['shippingModeId'];
    }

    this.shipmentStyleCode = shipmentDat['styleCode'];
    this.shipmentDataIndexKey = indexKey;
    this.showPackage = false;
    this.shpmtModeId = shipmentDat['shippingModeId'];
    if (shipmentDat['shippingMode'] === 'Less than Truckload (LTL)') {
      this.shipmentName = 'Less than Truckload';
    } else {
      this.shipmentName = shipmentDat['shippingMode'];
    }

    this.selectedPackagingTypeStyleCode = this.shipmentData[indexKey].styleCode;
    this.selectedPackagingTypeName = this.shipmentData[indexKey].shipmentCode;

    if (this.shipmentData[indexKey].shipmentTypeDetails === null) {
      this.saveQuoteDataObj['shpmtTypeId'] = null;
      this.shipmentDetailsExists = false;
    } else {
      this.shipmentDetailsExists = true;
      this.shipmentTypeDetails =
        this.shipmentData[indexKey].shipmentTypeDetails;
    }

    this.saveQuoteDataObj['shipmentMode'] = shipmentDat['shippingModeId'];
    this.freightClassApplicable = this.shipmentData[indexKey].isFreightNeeded;

    if (this.shipmentData[indexKey].isFreightNeeded) {
      for (
        let i = 0;
        i < this.shipmentItemsForm.get('inputFields')['controls'].length;
        i++
      ) {
        if (this.defaultHazmat == true) {
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .patchValue(this.defaultHazmat);
          this.showHideHazmatOptions(i, true);
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('class')
            .enable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('nmfc')
            .enable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('stack')
            .enable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .enable();
        } else {
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('class')
            .enable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('nmfc')
            .enable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('stack')
            .enable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .enable();
        }
      }
    } else {
      for (
        let i = 0;
        i < this.shipmentItemsForm.get('inputFields')['controls'].length;
        i++
      ) {
        if (this.defaultHazmat == false) {
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .patchValue(this.defaultHazmat);
          this.showHideHazmatOptions(i, false);
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('class')
            .disable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('nmfc')
            .disable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('stack')
            .disable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .disable();

          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('class')
            .reset();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('nmfc')
            .reset();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('stack')
            .reset();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .reset();
        } else {
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .patchValue(this.defaultHazmat);
          this.showHideHazmatOptions(i, false);
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('class')
            .disable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('nmfc')
            .disable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('stack')
            .disable();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .disable();

          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('class')
            .reset();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('nmfc')
            .reset();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('stack')
            .reset();
          (
            (this.shipmentItemsForm.get('inputFields') as FormArray).at(
              i
            ) as FormGroup
          )
            .get('hazmet')
            .reset();
        }
      }
    }
  }

  selectPackageType(packagingId, styleCode, packaginTypeName) {
    if (packagingId != 'zfa20f16-0d62-11ed-ae4b-4ea80e173030') { //if selected package is not custom package type then change the package Id in shipment items table
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          0
        ) as FormGroup
      )
        .get('packageType')
        .patchValue(packagingId);
    }
    this.packagingTypeId = packagingId;
    //  this.saveQuoteDataObj['shpmtPackagingTypeId'] = null;
    if (this.selectedPackagingTypeStyleCode == 'courier') {
      this.saveQuoteDataObj['shipmentSubPackageCode'] = this.saveQuoteDataObj['shpmtPackagingTypeId'] = packagingId;
    } else {
      this.saveQuoteDataObj['shipmentSubPackageCode'] = packagingId;
      this.saveQuoteDataObj['shpmtPackagingTypeId'] = '6963113f-0fa7-11ea-8462-163046d26cd4';
    }
    // if (
    //   this.shpmtModeId == 'cd6bdba2-e7ae-11e9-8462-163046d26cd4' && //LTL
    //   this.packagingTypeId == 'b8e221f4-f312-11e9-8462-163046d26cd4' //Pallet
    // ) {
    //   if (this.measurementType == 'metric') {
    //     //Metric
    //     (
    //       (this.shipmentItemsForm.get('inputFields') as FormArray).at(0) as FormGroup).get('width').patchValue(121.92);
    //     (
    //       (this.shipmentItemsForm.get('inputFields') as FormArray).at(
    //         0
    //       ) as FormGroup
    //     )
    //       .get('length')
    //       .patchValue(121.92);
    //   } else {
    //     //imperial
    //     (
    //       (this.shipmentItemsForm.get('inputFields') as FormArray).at(
    //         0
    //       ) as FormGroup
    //     )
    //       .get('width')
    //       .patchValue(48);
    //     (
    //       (this.shipmentItemsForm.get('inputFields') as FormArray).at(
    //         0
    //       ) as FormGroup
    //     )
    //       .get('length')
    //       .patchValue(48);
    //   }
    // }
    if (packaginTypeName == 'CP' || packaginTypeName == 'Custom Package') {

      const modalRef = this.modalService.open(CustomPackageComponent, {
        size: 'lg',
        centered: false,
        backdrop: true,
        // animation: true,
        windowClass: 'my-custom-modal13',
      });
      modalRef.componentInstance.shipmentMode = this.shipmentStyleCode;
      modalRef.componentInstance.spmntModeId = this.shpmtModeId;
      modalRef.componentInstance.measurementType = this.measurementType;
      modalRef.result.then((result: any) => {
        if (result[0].unit_mode == 'metric') {
          this.measurementType = 'metric'
        }
        if (result[0].unit_mode == 'imperial') {
          this.measurementType = 'imperial'
        }

        this.populateData(result);
        let num = 0;
        result.filter((element) => {
          if (element.shipment_mode_id == '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
            ((this.shipmentItemsForm.get('inputFields') as FormArray).at(num) as FormGroup).get('class').reset();
            ((this.shipmentItemsForm.get('inputFields') as FormArray).at(num) as FormGroup).get('class').patchValue('');
            ((this.shipmentItemsForm.get('inputFields') as FormArray).at(num) as FormGroup).get('nmfc').reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('stack')
              .reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('hazmet')
              .reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('class')
              .disable();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('nmfc')
              .disable();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('stack')
              .disable();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('hazmet')
              .disable();
            num++;
          } else {
            if (this.defaultHazmat == true) {
              (
                (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                  num
                ) as FormGroup
              )
                .get('hazmet')
                .patchValue(this.defaultHazmat);
              this.showHideHazmatOptions(num, true);
            }
            num++;
          }
        });
      });
    }
  }

  onSelectedPackage(value, index) {
    if (index == 0) {
      this.packagingTypeId = value;
      if (this.selectedPackagingTypeStyleCode == 'courier') {
        this.saveQuoteDataObj['shipmentSubPackageCode'] = this.saveQuoteDataObj[
          'shpmtPackagingTypeId'
        ] = value;
      } else {
        this.saveQuoteDataObj['shipmentSubPackageCode'] = value;
        this.saveQuoteDataObj['shpmtPackagingTypeId'] =
          '6963113f-0fa7-11ea-8462-163046d26cd4';
      }
    }
    if (this.shpmtModeId == '0a13d82b-f7ee-11e9-8462-163046d26cd4') {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('hazmet')
        .patchValue(this.defaultHazmat);
      this.showHideHazmatOptions(0, false);
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('class')
        .reset();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('nmfc')
        .reset();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('stack')
        .reset();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('hazmet')
        .reset();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('class')
        .disable();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('nmfc')
        .disable();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('stack')
        .disable();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('hazmet')
        .disable();
    } else {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('class')
        .enable();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('nmfc')
        .enable();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('stack')
        .enable();
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('hazmet')
        .enable();
      if (this.defaultHazmat == true) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            index
          ) as FormGroup
        )
          .get('hazmet')
          .patchValue(this.defaultHazmat);
        this.showHideHazmatOptions(index, true);
      }
    }
    if (
      this.shpmtModeId === 'cd6bdba2-e7ae-11e9-8462-163046d26cd4' &&
      this.packagingTypeId === 'b8e221f4-f312-11e9-8462-163046d26cd4'
    ) {
      // if (this.measurementType == 'metric') {
      //   //Metric
      //   (
      //     (this.shipmentItemsForm.get('inputFields') as FormArray).at(
      //       index
      //     ) as FormGroup
      //   )
      //     .get('width')
      //     .patchValue(121.92);
      //   (
      //     (this.shipmentItemsForm.get('inputFields') as FormArray).at(
      //       index
      //     ) as FormGroup
      //   )
      //     .get('length')
      //     .patchValue(121.92);
      // } else {
      //   //imperial
      //   (
      //     (this.shipmentItemsForm.get('inputFields') as FormArray).at(
      //       index
      //     ) as FormGroup
      //   )
      //     .get('width')
      //     .patchValue(48);
      //   (
      //     (this.shipmentItemsForm.get('inputFields') as FormArray).at(
      //       index
      //     ) as FormGroup
      //   )
      //     .get('length')
      //     .patchValue(48);
      // }
    } else {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('width')
        .patchValue('');
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          index
        ) as FormGroup
      )
        .get('length')
        .patchValue('');
    }
    if (value === 'afa20f16-0d62-11ed-ae4b-4ea80e173030') {
      const modalRef = this.modalService.open(CustomPackageComponent, {
        size: 'lg',
        centered: false,
        backdrop: true,
        // animation: true,
        windowClass: 'my-custom-modal13',
      });
      modalRef.componentInstance.shipmentMode = this.shipmentStyleCode;
      modalRef.componentInstance.spmntModeId = this.shpmtModeId;
      modalRef.componentInstance.measurementType = this.measurementType;
      modalRef.result.then((result) => {
        this.populateData(result, true);
        let num = 0;
        result.filter((element) => {
          if (
            element.shipment_mode_id == '0a13d82b-f7ee-11e9-8462-163046d26cd4'
          ) {
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('class')
              .reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('class')
              .patchValue('');
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('nmfc')
              .reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('stack')
              .reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('hazmet')
              .reset();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('class')
              .disable();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('nmfc')
              .disable();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('stack')
              .disable();
            (
              (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                num
              ) as FormGroup
            )
              .get('hazmet')
              .disable();
            num++;
          } else {
            if (this.defaultHazmat == true) {
              (
                (this.shipmentItemsForm.get('inputFields') as FormArray).at(
                  num
                ) as FormGroup
              )
                .get('hazmet')
                .patchValue(this.defaultHazmat);
              this.showHideHazmatOptions(num, true);
            }
            num++;
          }
        });
      });
    }
  }
  selectShipmentEquipmentDetails(associatedId, styleCode) {
    this.saveQuoteDataObj['shpmtTypeId'] = associatedId;
    this.shipmentTypeId = associatedId;
    //this.selectShipmentEquipmentDetails = styleCode;
  }

  editQuote(quoteData, indexKey) {
    this.editQuoteItemIndex = indexKey;
    this.dynamicItem = true;
    this.addItemForm['controls']['class'].setValue(quoteData.freightRange);
    this.addItemForm['controls']['description'].setValue(quoteData.description);
    this.addItemForm['controls']['hazmet'].setValue(quoteData.HazMat);
    this.addItemForm['controls']['height'].setValue(quoteData.height);
    this.addItemForm['controls']['length'].setValue(quoteData.length);
    if (quoteData.lengthType == 'metric') {
      quoteData.lengthType = true;
    } else {
      quoteData.lengthType = false;
    }

    this.addItemForm['controls']['lengthType'].setValue(quoteData.lengthType);
    this.addItemForm['controls']['nmfc'].setValue(quoteData.nmfc);

    this.addItemForm['controls']['pieceCount'].setValue(quoteData.qty);
    this.addItemForm['controls']['shipmentvalue'].setValue(
      quoteData.shipmentItemValue
    );
    this.addItemForm['controls']['stack'].setValue(quoteData.stackable);
    this.addItemForm['controls']['hazmet'].setValue(quoteData.hazMat);
    this.addItemForm['controls']['weight'].setValue(quoteData.totalWeight);
    this.addItemForm['controls']['width'].setValue(quoteData.breadth);
  }

  removeTag(index) {
    if (index !== -1) {
      this.acceserialStart.splice(index, 1);
      if (this.acceserialStart.length == 0) {
        this.accesserialLabelStart = 'Select Accessorial';
      }
    }
  }

  getfrieghtClass() {
    let url = ServerURLS.getFreightClass.action;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      this.freightClassObj = result['freight_info']['data'];
      this.freightDisplayDataObj = [];
      this.defaultHazmat = result['is_hazmat_default'];
      if (this.defaultHazmat == true) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            0
          ) as FormGroup
        )
          .get('hazmet')
          .patchValue(this.defaultHazmat);
        this.showHideHazmatOptions(0, true);
      }
      this.freightClassObj.forEach((data, index) => {
        this.freightDisplayDataObj[data.freightId] = data;
      });

      this.modalService.dismissAll();
    }).catch((error) => {

      this.modalService.dismissAll();
      this.router.navigate(['/signin']);
      throw error;
    })
  }


  customOptionsSelected(data) {
    this.getfrieghtClassId(data);
  }

  resetGlobalCalculation(addItemArray) {
    this.globalPieceCount['piececount'] =
      this.globalPieceCount['lineaerfeet'] =
      this.globalPieceCount['shipmentvalue'] =
      this.globalPieceCount['totalweight'] =
      this.globalPieceCount['shipmentvalue'] =
      0;
    this.globalPieceCount['class'] = ' ';
    addItemArray.forEach((data, indexkey) => {
      this.globalPieceCount['piececount'] += parseInt(data.qty);
      this.globalPieceCount['lineaerfeet'] += data.length;
      this.globalPieceCount['totalweight'] +=
        parseFloat(
          data.wghtUnit == 'lbs' ? data.totalWeight : data.totalWeight * 2.20462
        ) * parseInt(data.qty);
      this.globalPieceCount['shipmentvalue'] += parseFloat(
        data.shipmentItemValue
      ); //removed quantity
      this.globalPieceCount['class'] +=
        indexkey > 4
          ? ''
          : indexkey == 4
            ? ',..'
            : (indexkey != 0 ? ', ' : '') +
            (data['freightRange'] ? data['freightRange'] : '');
    });
    localStorage.setItem('_admintotalweight', this.globalPieceCount['totalweight']);
    localStorage.setItem(
      '_adminshipmentvalue',
      this.globalPieceCount['shipmentvalue']
    );
  }

  remove(index) {
    this.saveQuoteDataObj['items'].splice(index, 1);
    this.addItemArray.splice(index, 1);
    this.resetGlobalCalculation(this.addItemArray);
  }

  updateDate(event) {

    const pattern = /^\d{4}([./-])\d{2}\1\d{2}$/;
    let inputChar = event.target.value;
    inputChar = inputChar.split('-');
    inputChar = inputChar[2] + '-' + inputChar[0] + '-' + inputChar[1];
    let minDate = this.minDate.split('-');
    minDate = minDate[2] + '-' + minDate[0] + '-' + minDate[1];
    let d1 = Date.parse(inputChar);
    let d2 = Date.parse(minDate);

    if (d1 < d2) {
      this.dateVal = false;
      return;
    } else {
      this.dateVal = true;
    }
    if (!pattern.test(inputChar)) {
      this.dateVal = false;
      return;
    } else if ('undefined' == typeof this.todaysDate || '' == this.todaysDate) {
      this.dateVal = false;
      return;
    } else {
      this.dateVal = true;
      this.todaysDate = event.target.value;
    }
  }

  refreshList(action) {
    if (action == 'shipper') {
      if ('' == this.saveQuoteDataObj['sourceAddress']['addrTypeId']) {
        this.toastr.error('Please select shipper address type', '', {
          timeOut: 2000,
        });
        return;
      }

      if (!this.accessorialData['start']) {
        this.onOptionsSelected(
          this.saveQuoteDataObj['sourceAddress']['addrTypeId']
        );
      }
    } else {
      if ('' == this.saveQuoteDataObj['destinationAddress']['addrTypeId']) {
        this.toastr.error('Please select consignee address type', '', {
          timeOut: 2000,
        });
        return;
      }

      if (!this.accessorialData['end']) {
        this.onOptionsSelectedConsignee(
          this.saveQuoteDataObj['destinationAddress']['addrTypeId']
        );
      }
    }
  }


  onOptionsSelected(location) {
    this.showShipperWarningAdd = false;
    this.saveQuoteDataObj['sourceAddress']['addrTypeId'] = location;
    this.accesserialLabelStart = 'Loading Accessorial..';
    this.acceserialStart = [];

    var url = ServerURLS.getLocationExtraServices.action + '?locTypeId=' + location + '&locType=start' + '&customerId=' + this.customerId+'&shipmentCode='+this.shipmentCode;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((data) => {
      
      this.accessorialData['start'] = data['data'];
    this.accesserialLabelStart = 'Select Accessorial';

      this.accessorialData['start'].forEach((element) => {
        if (element.isDefault == true) {
          
          this.acceserialStart.push(element);
          this.accesserialLabelStart='';
        }
      });
    })

   
    if (this.shearchaddress2) {
      this.accessorialData['start'].forEach((item, index) => {
        this.detailsOfCompany['accessorialDetails']['accessorialList'].forEach(
          (itm, i) => {
            if (item.servIceId == itm.id) {
              this.selectAccessorial(index, 'start');
            }
          }
        );
      });
    }

  }

  removeConsigneeAcc(index) {
    if (index !== -1) {
      this.acceserialEnd.splice(index, 1);
      if (this.acceserialEnd.length == 0) {
        this.accesserialLabelEnd = ' Select Accessorial';
      }
    }
  }

  onOptionsSelectedConsignee(location, type?) {
    this.showConsigneeWarningAdd = false;
    this.saveQuoteDataObj['destinationAddress']['addrTypeId'] = location;
    this.acceserialEnd=[];
    this.accesserialLabelEnd = 'Loading Accessorial..';
    var url3 = ServerURLS.getLocationExtraServices.action + '?locTypeId=' + location + '&locType=end' + '&customerId=' + this.customerId+'&shipmentCode='+this.shipmentCode;
    let promise = this.httpService.getHttpService(url3, '');
    promise.then((data:any) => { 
      
      this.accessorialData['end'] = data['data'];
      this.accesserialLabelEnd = 'Select Accessorial';
      if (this.shipAgainQuoteID && !type) {
       this.getExtraservicesData(this.shipAgainQuoteID);
      }
      
      this.accessorialData['end'].filter((element) => {

        if (element.isDefault == true) {
          this.accesserialLabelEnd='';
          
          this.acceserialEnd.push(element);
          
        }
      });
      console.log(this.acceserialEnd,"Accessorial")

      if (this.shearchaddress2) {
        this.accessorialData['end'].forEach((item, index) => {
          this.detailsOfCompany['accessorialDetails'][
            'accessorialList'
          ].forEach((itm, i) => {
            if (item.servIceId == itm.id) {
              this.selectAccessorial(index, 'end');
            }
          });
        });
      }

     
      // this.setDefaultAccessoral(this.accessorialData['end'],location)
    });
  }



  setDefaultAccessoral(accessoralData?:any,location?:string){
    let selectedLocation = this.locationType?this.locationType.find(business => business.locId === location):'';
    if (selectedLocation && selectedLocation.locTypeCode === 'R&H' && this.shipmentCode=='LTL') {
        const filteredData = accessoralData.filter((item: any) => item.serviceCode === 'CFAPPT' || item.serviceCode === 'LFTD')
            .map((item: any) => ({
              serviceName: item.serviceName,
              servIceId: item.servIceId,
              hideCloseIcon:true,
              assocId:item.assocId
            }));
            this.acceserialEnd = filteredData;
            this.accesserialLabelEnd=''
    } else {
      this.acceserialEnd = [];
      this.accesserialLabelEnd = 'Select Accessorial';

    } 

  }
  selectAccessorial(index, accessorialType) {
    if (accessorialType == 'start') {
      if (
        this.checkAccessorialAlreadyExists(
          'start',
          this.accessorialData['start'][index].assocId
        )
      ) {
        this.acceserialStart.push(this.accessorialData['start'][index]);
        this.accesserialLabelStart = '';
      }
    } else {
      if (
        this.checkAccessorialAlreadyExists(
          'end',
          this.accessorialData['end'][index].assocId
        )
      ) {
        this.acceserialEnd.push(this.accessorialData['end'][index]);
        this.accesserialLabelEnd = '';
      }
    }
  }

  checkAccessorialAlreadyExists(accessorialType, accessorialId) {
    var flag = true;
    if (accessorialType == 'start' && this.acceserialStart) {
      this.acceserialStart.forEach((data, index) => {
        if (data.assocId === accessorialId) {
          flag = false;
        }
      });
      return flag;
    } else if (accessorialType == 'start' && !this.acceserialStart) {
      return true;
    }

    if (accessorialType == 'end' && this.acceserialEnd) {
      this.acceserialEnd.forEach((data, index) => {
        if (data.assocId === accessorialId) {
          flag = false;
        }
      });
      return flag;
    } else if (accessorialType == 'end' && !this.acceserialEnd) {
      return true;
    }
  }

  displayCurrency() {
    if (this.countryShipperString === 'CA' && this.countryConsigneeString === 'CA') {
      this.setcurrency[0] = 'CAD';
    } else if (this.countryShipperString === 'US' && this.countryConsigneeString === 'CA') {
      this.setcurrency[0] = 'USD';
    } else if (this.countryShipperString === 'CA' && this.countryConsigneeString === 'US') {
      this.setcurrency[0] = 'USD';
    } else if (this.countryShipperString === 'US' && this.countryConsigneeString === 'US') {
      this.setcurrency[0] = 'USD';
    } else {
      this.setcurrency[0] = 'CAD';
    }
  }

  shipperZIPCode = this.extractZipCode(this.shipperFullAddr);
  consigneeZIPCode = this.extractZipCode(this.consigneeFullAddr);
  //  extractZipCode(address) {
  //     const parts = address.split(',');
  //     const lastPart = parts[parts.length - 2].trim();
  //     const zipCodeMatch = lastPart.match(/\b\d{5}(?:-\d{4})?\b|\b[A-CEGHJ-NPR-TV-Z]\d[A-CEGHJ-NPR-TV-Z] ?\d[A-CEGHJ-NPR-TV-Z]\d\b/);
  //     return zipCodeMatch ? zipCodeMatch[0] : null;
  // }
  extractZipCode(address) {
    // Split the address by ',' and trim each part
    const parts = address.split(',').map(part => part.trim());
    // Get the second last part of the address (index -2) after trimming
    const lastPart = parts[parts.length - 2];
    // Check if lastPart is defined, not null, and not an empty string before calling trim()
    if (lastPart && lastPart !== '') {
      const trimmedPart = lastPart.trim();
      const zipCodeMatch = trimmedPart.match(/\b\d{5}(?:-\d{4})?\b|\b[A-CEGHJ-NPR-TV-Z]\d[A-CEGHJ-NPR-TV-Z] ?\d[A-CEGHJ-NPR-TV-Z]\d\b/);
      return zipCodeMatch ? zipCodeMatch[0] : null;
    }
    return null; // Return null or handle the undefined case based on your requirements
  }

  saveQuote() {
    this.shipperZIPCode = this.extractZipCode(this.shipperFullAddr);
    this.consigneeZIPCode = this.extractZipCode(this.consigneeFullAddr)
    this.saveQuoteDataObj['sourceAddress']['zipCode'] = this.shipperZIPCode
    this.saveQuoteDataObj['destinationAddress']['zipCode'] = this.consigneeZIPCode
    this.displayData = true;
    this.saveQuoteDataObj['customerId'] = this.customerId;
    this._loaderService.stringSubject.next(true);
    this.saveShipmentItems();
    if (null == this.saveQuoteDataObj['shipmentMode']) {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Please select Shipment Mode', '', {
        timeOut: 10000,
      });
      return;
    }
    if (null == this.saveQuoteDataObj['shpmtPackagingTypeId']) {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Please select Package Type ', '', {
        timeOut: 10000,
      });
      return;
    }
    if (!this.saveQuoteDataObj['sourceAddress']['zipCode'] && !this.saveQuoteDataObj['sourceAddress']['city']) {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.showShipperWarning = true;
      this.toastr.error('Please select shipper address', '', {
        timeOut: 10000,
      });
      return;
    }
    if(!this.saveQuoteDataObj['sourceAddress']['zipCode']){
      this.displayData=false;
      this._loaderService.stringSubject.next(false);
      this.showShipperWarning=true;
      this.toastr.error("Please Select the Shipper ZipCode",'',{timeOut:3000});
      return;
    }
    if(''== this.saveQuoteDataObj['sourceAddress']['city']){
      this.displayData=false;
      this._loaderService.stringSubject.next(false);
      this.showShipperWarning=true;
      this.toastr.error("Please Select the Shipper City",'',{timeOut:3000});
      return;
    }

    if ('' == this.saveQuoteDataObj['sourceAddress']['addrTypeId']) {
      this.showShipperWarningAdd = true;
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Please select shipper address type', '', {
        timeOut: 10000,
      });
      return;
    }
    this.showShipperWarning = false;
    if (!this.saveQuoteDataObj['destinationAddress']['zipCode'] && !this.saveQuoteDataObj['destinationAddress']['city']) {
      this.showConsigneeWarning = true;
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error('Please select consignee address', '', {
        timeOut: 10000,
      });
      return;
    }
    if(!this.saveQuoteDataObj['destinationAddress']['zipCode']){
      this.showConsigneeWarning=true;
      this.displayData=false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error("Please Select Consignee ZipCode",'',{timeOut:3000});
      return;
    }
    if(''==this.saveQuoteDataObj['destinationAddress']['city']){
      this.showConsigneeWarning=true;
      this.displayData=false;
      this._loaderService.stringSubject.next(false);
      this.toastr.error("Please Select Consignee City",'',{
        timeOut:3000
      })
      return;
    }
    this.showShipperWarning = false;
    if ('' == this.saveQuoteDataObj['destinationAddress']['addrTypeId']) {
      this.showConsigneeWarningAdd = true;
      this.toastr.error('Please select consignee address type', '', {
        timeOut: 10000,
      });
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      return;
    }
    this.showConsigneeWarning = false;
    if ('undefined' == typeof this.todaysDate || '' == this.todaysDate) {
      this.dateVal = false;
      return;
    }
    //if incorrect date selected by user show toast
    if (!this.dateVal) {
      this.toastr.error('Please select valid pickup date.', '', {
        timeOut: 10000,
      });
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      return;
    }
    let todaysDateBr = this.selectedDate;
    this.saveQuoteDataObj['delvryForm']['pickupDate1'] = {
      year: todaysDateBr.year,
      month: todaysDateBr.month,
      day: todaysDateBr.day,
    };
    
    this.saveQuoteDataObj['delvryForm']['pickupDate'] =
      todaysDateBr.year +
      '-' +
      String(todaysDateBr.month).padStart(2, '0') +
      '-' +
      String(todaysDateBr.day).padStart(2, '0');
    
    // this.toastr.success(this.saveQuoteDataObj['delvryForm']['pickupDate']);
    if (this.saveQuoteDataObj['items'].length === 0) {
      this.toastr.error('Please fill all the required details in shipment items.', '', {
        timeOut: 10000,
      });
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      return;
    }
    this.saveQuoteDataObj['extraServices'] = [];
    if (Object.keys(this.acceserialStart).length != 0) {
      for (let key in this.acceserialStart) {
        this.saveQuoteDataObj['extraServices'].push(
          this.acceserialStart[key]['assocId']
        );
      }
    }
    if (Object.keys(this.acceserialEnd).length != 0) {
      for (let key in this.acceserialEnd) {
        this.saveQuoteDataObj['extraServices'].push(
          this.acceserialEnd[key]['assocId']
        );
      }
    }
    //Save status for extra services
    (this.saveQuoteDataObj['hasSourceServicesOpted'] = this.acceserialStart.length ? true : false),
      (this.saveQuoteDataObj['hasDestServicesOpted'] = this.acceserialEnd.length ? true : false),
      (this.openModel());

    // Set blank addressLine2 and fullAddress for shipper and consignee - In case of google address only
    this.saveQuoteDataObj['sourceAddress']['fullAddr'] = '';
    this.saveQuoteDataObj['destinationAddress']['fullAddr'] = '';
    // this.saveQuoteDataObj['sourceAddress']['addrLine2'] = '';
    // this.saveQuoteDataObj['destinationAddress']['addrLine2'] = '';


    // const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    if (this.saveQuoteDataObj['destinationAddress']['zipCode'].length > 0 && this.saveQuoteDataObj['sourceAddress']['zipCode'].length > 0 && this.shipperZIPCode == this.saveQuoteDataObj['sourceAddress']['zipCode'] && this.consigneeZIPCode == this.saveQuoteDataObj['destinationAddress']['zipCode']) {
      let url = ServerURLS.saveQuoteStep_one.action;
      sessionStorage.setItem('_adminsaveQuoteDataObj', JSON.stringify(this.saveQuoteDataObj));

      let promise = this.httpService.postFileHttpService(url, this.saveQuoteDataObj);
      promise.then((result) => {
        this.displayData = false
        this._loaderService.stringSubject.next(false);
        this.modalService.dismissAll();
        if (result['data'] == null) {
          this.toastr.error(result['message'], '', {
            timeOut: 10000,
          });
          return;
        }
        localStorage.setItem('_adminactiveQuoteId', result['data']);
        let savedDate = this.saveQuoteDataObj['delvryForm']['pickupDate'].split('-');
        savedDate = savedDate[1] + '-' + savedDate[2] + '-' + savedDate[0];
        let savedDateObj = {
          quoteId: result['data'],
          savedDate: savedDate,
        };
        sessionStorage.setItem('_adminorderPickupDate', JSON.stringify(savedDateObj));
      let data = {
        'origin': {
          'countryCode': this.saveQuoteDataObj.sourceAddress.country,
          'stateCode': this.saveQuoteDataObj.sourceAddress.state,
          'city': this.saveQuoteDataObj.sourceAddress.city
        },
        'destination': {
          'countryCode': this.saveQuoteDataObj.destinationAddress.country,
          'stateCode': this.saveQuoteDataObj.destinationAddress.state,
          'city': this.saveQuoteDataObj.destinationAddress.city
        },
        'shipmentMode':this.saveQuoteDataObj['shipmentMode']
      }
      //  
      sessionStorage.setItem('_admincountryDataForMarchInsurance', JSON.stringify(data))
        sessionStorage.setItem('_adminorderPickupDate', JSON.stringify(savedDateObj));
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfQuoteHistory()
        if (this.saveForLaterEnable === false) {
          sessionStorage.setItem('_admingetQuoteCustomerId', this.customerId)
          this.router.navigate(['shipcarte/carrier-selection/', result['data']]);
        } else {
          this.saveForLaterEnable = false;
        }
      }).catch((error) => {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        if (error.status == 401) {
          this.toastr.error("Sorry your session expired!", 'Please re-login and try again!', {
            timeOut: 10000,
          });
          this.router.navigate(['/signin']);
        } else {
          let errorMessage="Please verify your address and other input details!"
          if(error && error['error'] != null && error['error']["message"] && error['error']["message"] !=null){
            errorMessage=error['error']["message"];
          }
          
          this.toastr.error( 'Please try again!', errorMessage, {
            timeOut: 10000,
          });
        }
        
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        this.modalService.dismissAll();
      });
    }
    else {
      this.toastr.error('Please enter valid zipCode', '', {
        timeOut: 5000,
      });
      this.displayData = false
      this._loaderService.stringSubject.next(false);
      return;
    }
  }
  addressChanges(value,mode){
    if(value==''){
      if(mode=='shipper'){
        this.shipperFullAddr=''
      }else if(mode=='con'){
        this.consigneeFullAddr=''
      }
    }
  }
  saveForLater() {
    this.saveForLaterEnable = true;
    this.saveQuote();
  }

  alterAddress(event, action) {
    let tempAdressContainer = this.saveQuoteDataObj['sourceAddress'];
    this.saveQuoteDataObj['sourceAddress'] =
      this.saveQuoteDataObj['destinationAddress'];
    this.saveQuoteDataObj['destinationAddress'] = tempAdressContainer;
  }

  getDensity() {
    let formFields =
      this.shipmentItemsForm.value.inputFields[this.densityCounter];
    this.height = formFields.height;
    this.weight = formFields.weight;
    this.length = formFields.length;
    this.width = formFields.width;

    if (!this.freightClassApplicable) return;

    this.height = parseFloat(this.height);
    this.width = parseFloat(this.width);
    this.length = parseFloat(this.length);
    var weight = this.weight;

    if (!this.height && !this.width && !this.length) {
      return;
    }
    if (this.measurementType === 'metric') {
      //cm to inch
      var volume =
        this.height *
      
        (this.width ) *
        (this.length );
      weight = this.weight;
    } else {
      var volume = this.height * this.width * this.length;
    }
    var density = weight / (volume / 1728);
    this.freightClassCal(density);
  }

  setValue() {
    //If Metric cm and kg
    //else imperial inch and Lbs
    if (
      this.shpmtModeId == 'cd6bdba2-e7ae-11e9-8462-163046d26cd4' && //LTL
      this.packagingTypeId == 'b8e221f4-f312-11e9-8462-163046d26cd4' //Pallet
    ) {
      if (this.measurementType == 'metric') {
        //Metric
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            0
          ) as FormGroup
        )
          .get('width')
          .patchValue(121.92);
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            0
          ) as FormGroup
        )
          .get('length')
          .patchValue(121.92);
      } else {
        //imperial
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            0
          ) as FormGroup
        )
          .get('width')
          .patchValue(48);
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            0
          ) as FormGroup
        )
          .get('length')
          .patchValue(48);
      }
    } else {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          0
        ) as FormGroup
      )
        .get('width')
        .patchValue('');
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          0
        ) as FormGroup
      )
        .get('length')
        .patchValue('');
    }
  }

  setUnitValue(value) {
    this.measurementType = value;
    // this.setValue();
  }

  freightClassCal(density) {
    this.classToSet = [];
    // if (this.orderIsWithInCanada) {
    //   // Both countries are CA, set .get('class') to null
    //   (
    //     (this.shipmentItemsForm.get('inputFields') as FormArray).at(
    //       this.densityCounter
    //     ) as FormGroup
    //   )
    //     .get('class')
    //     .patchValue(null);
    //   return;
    // }
    if (density >= 30) {
      // 60
      this.classToSet[this.densityCounter] = this.freightClassObj[2];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 22.5 && density < 30) {
      // 65
      this.classToSet[this.densityCounter] = this.freightClassObj[3];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 15 && density < 22.5) {
      // 70
      this.classToSet[this.densityCounter] = this.freightClassObj[4];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 12 && density < 15) {
      // 85
      this.classToSet[this.densityCounter] = this.freightClassObj[6];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 10 && density < 12) {
      // 92.5
      this.classToSet[this.densityCounter] = this.freightClassObj[7];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 8 && density < 10) {
      // 100
      this.classToSet[this.densityCounter] = this.freightClassObj[8];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 6 && density < 8) {
      // 125
      this.classToSet[this.densityCounter] = this.freightClassObj[10];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 4 && density < 6) {
      // 175
      this.classToSet[this.densityCounter] = this.freightClassObj[12];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 2 && density < 4) {
      // 250
      this.classToSet[this.densityCounter] = this.freightClassObj[14];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);
      // this.getfrieghtClassId(this.classToSet[this.densityCounter]);
      return;
    }
    if (density >= 1 && density < 2) {

      this.classToSet[this.densityCounter] = this.freightClassObj[15];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);

      return;
    }
    if (density < 1) {
      // 400
      this.classToSet[this.densityCounter] = this.freightClassObj[16];
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          this.densityCounter
        ) as FormGroup
      )
        .get('class')
        .patchValue(this.classToSet[this.densityCounter]['freightType']);

      return;
    }
  }

  backToQuote() {
    window.history.back();
  }

  packgeSelect(packagingId, packagingType) {
    this.packagingType = packagingId != '' ? '' : this.packagingType;
  }

  onSubmit() {

    if (
      !this.addItemForm.value.shipmentvalue &&
      this.countryShipperString != this.countryConsigneeString
    ) {
      this.showShipmentValueAdd = true;
      this.toastr.error('Please enter shipment item value', '', {
        timeOut: 5000,
      });
      return;
    } else {
      this.showShipmentValueAdd = false;
    }
    if (!this.addItemForm.value.shipmentvalue) {
      this.addItemForm.controls['shipmentvalue'].setValue(0);
    } else {
      this.showShipmentValueAdd = false;
    }
    this.dynamicItem = false;
    if (
      this.addItemForm.value.class == '' ||
      this.addItemForm.value.class == null
    ) {
      this.addItemForm.value.class = this.classToSet['freightType'];
    }

    if (this.addItemForm.value.pieceCount != '') {
      this.getfrieghtClassId(this.addItemForm.value.class);
      var lastLengthType = this.addItemForm.value.lengthType;
      let inputData = {
        qty: this.addItemForm.value.pieceCount.toString(),
        totalWeight: parseFloat(this.addItemForm.value.weight).toFixed(2),
        length: parseFloat(this.addItemForm.value.length).toFixed(2),
        breadth: parseFloat(this.addItemForm.value.width).toFixed(2),
        height: parseFloat(this.addItemForm.value.height).toFixed(2),
        unit: this.addItemForm.value.lengthType ? 'cm' : 'inch',
        wghtUnit: this.addItemForm.value.lengthType ? 'kg' : 'lbs',
        freightClass: this.freightClassSelectedId,
        freightRange: this.addItemForm.value.class,
        packaging: this.selectedPackageTypeId,
        styleCode: this.selectedPackagingTypeStyleCode,
        quoteItemId: null,
        showFreight: true,
        lengthType: this.addItemForm.value.lengthType ? 'metric' : 'imperial',
        description: this.addItemForm.value.description,
        stackable: this.addItemForm.value.stack,
        hazMat: this.addItemForm.value.hazmet,
        shipmentItemValue: this.addItemForm.value.shipmentvalue,
        shipmentItemValueCurrency: this.setcurrency[0],
        nmfc: this.addItemForm.value.nmfc,
        displayOrder: this.displayOrder++,
      };
      if (this.editQuoteItemIndex == -1) {
        this.addItemArray.push(inputData);
      } else {
        this.addItemArray[this.editQuoteItemIndex] = inputData;
      }
    }

    this.resetGlobalCalculation(this.addItemArray);
    this.saveQuoteDataObj['items'] = [...this.addItemArray];

    this.editQuoteItemIndex = -1;
    this.addItemForm.reset();

    this.addItemForm['controls']['lengthType'].setValue(lastLengthType);

    if (
      this.shpmtModeId == 'cd6bdba2-e7ae-11e9-8462-163046d26cd4' && //LTL
      this.packagingTypeId == '6963113f-0fa7-11ea-8462-163046d26cd4' && //Pallet
      this.selectedPackageTypeId == 'b8e221f4-f312-11e9-8462-163046d26cd4' //Sub Package Palle
    ) {
      this.addItemForm['controls']['width'].setValue(
        !lastLengthType ? 48 : 121.92
      );
      this.addItemForm['controls']['length'].setValue(
        !lastLengthType ? 48 : 121.92
      );
    } else {
      this.addItemForm.reset();
      this.addItemForm['controls']['lengthType'].setValue(lastLengthType);
    }
    this.addItemForm['controls']['shipmentcurrencyValue'].setValue(
      this.setcurrency[0]
    );
  }

  _keyUp(event: any) {
    const pattern = /^\d{4}([./-])\d{2}\1\d{2}$/;
    let inputChar = String.fromCharCode(event.key);

    if (!pattern.test(inputChar)) {
      event.stopPropagation();
    }
  }

  getfrieghtClassId(freightRange) {
    this.freightClassObj.forEach((data, index) => {
      if (
        this.freightDisplayDataObj[data.freightId].freightType === freightRange
      ) {
        this.freightClassSelectedId = data.freightId;
      }
    });
    return this.freightClassSelectedId;
  }

  getResultFromMapService(request, detailsFor) {
    var self = this;
    this.mapService.getDetails(request, function (result, status) {
 
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (result.business_status) {
          if (detailsFor == 'start') {
            self.saveQuoteDataObj['sourceAddress']['company'] = result.name;
          } else {
            self.saveQuoteDataObj['destinationAddress']['company'] =
              result.name;
              
          }
        } else {
          if (detailsFor == 'start') {
            self.saveQuoteDataObj['sourceAddress']['company'] = null;
          } else {
            self.saveQuoteDataObj['destinationAddress']['company'] = null;
          }
        }
      }
    });
  }

  selectSubPackage(subPackageId, subPackageStyleCode, index) {
    if (subPackageStyleCode == 'pallets') {
      this.addItemForm.controls['lengthType'].setValue(false);
      this.addItemForm['controls']['width'].setValue(48);
      this.addItemForm['controls']['length'].setValue(48);
    } else {
      this.addItemForm['controls']['width'].setValue('');
      this.addItemForm['controls']['length'].setValue('');
    }
    this.selectedPackageTypeId = subPackageId;
    //  this.freightClassApplicable = this.subPackageTypes[index]?.freightNeeded;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          if (position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            this.latlng[0] = [lat, lng]; //set to shipcarte office for now.
            this.origin = { lat: lat, lng: lng };
            this.destination = { lat: lat, lng: lng };
          }
        },
        (error: PositionError) => { }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }




  getExtraservicesData(quoteId) {
    let url = ServerURLS.getExtraservicesData.action + '?quoteId=' + quoteId + '&locType=start';
    let promise = this.httpService.getHttpService(url, '');
    promise.then((result) => {
      if (result['data']) {
        this.accessorialData['start'].forEach((item, index) => {
          result['data'].forEach((itm, i) => {
            if (item.servIceId == itm.uuid) {
              this.selectAccessorial(index, 'start');
            }
          });
        });
      }
    })

    let url1 = ServerURLS.getExtraservicesData.action + '?quoteId=' + quoteId + '&locType=end';
    let promise1 = this.httpService.getHttpService(url1, '');
    promise1.then((result) => {
      if (result['data']) {
        this.accessorialData['end'].forEach((item, index) => {
          result['data'].forEach((itm, i) => {
            if (item.servIceId == itm.uuid) {
              this.selectAccessorial(index, 'end');
            }
          });
        });
      }
    })

  }

  checkshipperAddressBook() {
    this.showDetailedAddressError = false;
    this.showShipperWarning = false;
    this.shearchaddress = this.detailsForm.controls['shipperAddressBook'].value;
    this.saveQuoteDataObj['shipperCompanyId'] = null;
    this.saveQuoteDataObj['sourceAddress']['fullAddr'] = '';
    this.saveQuoteDataObj['sourceAddress']['addrLine1'] = '';
    this.saveQuoteDataObj['sourceAddress']['addrLine2'] = '';
    this.saveQuoteDataObj['sourceAddress']['city'] = '';
    this.saveQuoteDataObj['sourceAddress']['country'] = '';
    this.countryShipperString = '';
    this.saveQuoteDataObj['sourceAddress']['state'] = '';
    this.saveQuoteDataObj['sourceAddress']['company'] = '';
    this.saveQuoteDataObj['sourceAddress']['zipCode'] = '';
    this.saveQuoteDataObj['sourceAddress']['latitude'] = '';
    this.saveQuoteDataObj['sourceAddress']['longitude'] = '';
    this.detailsForm.controls['locationTypeStart'].setValue('');
    this.detailsForm.controls['shipperAddress'].reset();
    this.acceserialStart = [];
  }

  checkAccesserial(){
    

    
    setTimeout(() => {
      if(this.detailsForm.controls['locationTypeEnd'].value==''){
      
        this.accessorialData['end']=[]
      }
      
    }, 1000);

  }

  checkConsigneeAddressBook() {
    this.showDetailedEndAddressError = false;
    this.showConsigneeWarning = false;
    this.shearchaddress2 =
      this.detailsForm.controls['consigneeAddressBook'].value;
    this.saveQuoteDataObj['destinationAddress']['fullAddr'] = '';
    this.saveQuoteDataObj['destinationAddress']['addrLine1'] = '';
    this.saveQuoteDataObj['destinationAddress']['addrLine2'] = '';
    this.saveQuoteDataObj['destinationAddress']['city'] = '';
    this.saveQuoteDataObj['destinationAddress']['state'] = '';
    this.saveQuoteDataObj['destinationAddress']['country'] = '';
    this.countryConsigneeString = '';
    this.saveQuoteDataObj['destinationAddress']['company'] = '';
    this.saveQuoteDataObj['destinationAddress']['zipCode'] = '';
    this.saveQuoteDataObj['destinationAddress']['latitude'] = '';
    this.saveQuoteDataObj['destinationAddress']['longitude'] = '';
    this.saveQuoteDataObj['destinationAddress']['firstName'] = '';
    this.saveQuoteDataObj['destinationAddress']['lastName'] = '';

    this.detailsForm.controls['locationTypeEnd'].setValue('');
    this.detailsForm.controls['consigneeAddress'].reset();
    this.acceserialEnd = [];
  }
  selectedShipperAddressCountry: any = '';
  selectedCosigneeAddressCountry: any = '';
  getCompanyDetails(i, companyId, flag) {


    this.openModel();
    let url = ServerURLS.getContactDetailsById.action + '?companyId=' + companyId;
    let promise = this.httpService.getHttpService(url, '');
    promise.then((res) => {

      this.detailsOfCompany = res;
      if (flag == 'start') {
        this.selectedShipperAddressCountry = res && res['companyDetails'] ? res['companyDetails']['country'] : undefined;
        // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada()
        if (this.detailsOfCompany['accessorialDetails']['locationTypeId']) {
          this.detailsForm.controls['locationTypeStart'].setValue(
            this.detailsOfCompany['accessorialDetails']['locationTypeId']
          );
          this.onOptionsSelected(
            this.detailsOfCompany['accessorialDetails']['locationTypeId']
          );
        }
        this.latlng[0] = [
          parseFloat(this.detailsOfCompany['companyDetails']['latitude']),
          parseFloat(this.detailsOfCompany['companyDetails']['longitude']),
        ];
        this.origin = {
          lat: parseFloat(
            this.detailsOfCompany['companyDetails']['latitude']
          ),
          lng: parseFloat(
            this.detailsOfCompany['companyDetails']['longitude']
          ),
        };
        this.saveQuoteDataObj['shipperCompanyId'] = this.detailsOfCompany['companyId'];
        if (this.addressData[i]['fullAddress']) {
          // this.saveQuoteDataObj['sourceAddress']['fullAddr'] = this.addressData[i]['fullAddress'];
          this.shipperFullAddr = this.addressData[i]['fullAddress'];
          this.detailsForm.controls['shipperAddress'].setValue(this.addressData[i]['fullAddress']);
        } else {
          this.detailsForm.controls['shipperAddress'].setValue(
            this.detailsOfCompany['companyDetails']['streetLine1'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['streetLine2'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['zipcode'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['city'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['province'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['country']
          );
          // this.saveQuoteDataObj['sourceAddress']['fullAddr'] =
          //   this.detailsOfCompany['companyDetails']['streetLine1'] +
          //   ',' +
          //   this.detailsOfCompany['companyDetails']['streetLine2'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['zipcode'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['city'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['province'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['country'];
          this.shipperFullAddr =
            this.detailsOfCompany['companyDetails']['streetLine1'] +
            ',' +
            this.detailsOfCompany['companyDetails']['streetLine2'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['zipcode'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['city'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['province'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['country'];
        }
        this.saveQuoteDataObj['sourceAddress']['addrLine1'] =
          this.detailsOfCompany['companyDetails']['streetLine1'];
        this.saveQuoteDataObj['sourceAddress']['addrLine2'] =
          this.detailsOfCompany['companyDetails']['streetLine2'];
        this.saveQuoteDataObj['sourceAddress']['city'] =
          this.detailsOfCompany['companyDetails']['city'];
        this.countryShipperString = this.saveQuoteDataObj['sourceAddress'][
          'country'
        ] = this.detailsOfCompany['companyDetails']['country'];
        this.saveQuoteDataObj['sourceAddress']['state'] =
          this.detailsOfCompany['companyDetails']['province'];
        this.saveQuoteDataObj['sourceAddress']['company'] =
          this.detailsOfCompany['companyDetails']['companyName'];
        this.saveQuoteDataObj['sourceAddress']['zipCode'] =
          this.detailsOfCompany['companyDetails']['zipcode'];
        this.saveQuoteDataObj['sourceAddress']['latitude'] = parseFloat(
          this.detailsOfCompany['companyDetails']['latitude']
        );
        this.saveQuoteDataObj['sourceAddress']['longitude'] = parseFloat(
          this.detailsOfCompany['companyDetails']['longitude']
        );

        this.modalService.dismissAll();
      } else {
        this.selectedCosigneeAddressCountry = res && res['companyDetails'] ? res['companyDetails']['country'] : undefined;
        // this.removeClassAndNMFCInputFromShipmentTableWhenOrderIsWithInCanada();
        this.saveQuoteDataObj['consigneeCompanyId'] =
          this.detailsOfCompany['companyId'];
        if (this.detailsOfCompany['accessorialDetails']['locationTypeId']) {
          this.detailsForm.controls['locationTypeEnd'].setValue(
            this.detailsOfCompany['accessorialDetails']['locationTypeId']
          );
          this.onOptionsSelectedConsignee(
            this.detailsOfCompany['accessorialDetails']['locationTypeId']
          );
        }
        this.latlng[1] = [
          parseFloat(this.detailsOfCompany['companyDetails']['latitude']),
          parseFloat(this.detailsOfCompany['companyDetails']['longitude']),
        ];
        this.destination = {
          lat: parseFloat(
            this.detailsOfCompany['companyDetails']['latitude']
          ),
          lng: parseFloat(
            this.detailsOfCompany['companyDetails']['longitude']
          ),
        };
        if (this.ConsigneeAddressData[i]['fullAddress']) {
          this.detailsForm.controls['consigneeAddress'].setValue(
            this.ConsigneeAddressData[i]['fullAddress']
          );
          // this.saveQuoteDataObj['destinationAddress']['fullAddr'] = this.ConsigneeAddressData[i]['fullAddress'];
          this.consigneeFullAddr = this.ConsigneeAddressData[i]['fullAddress'];
        } else {
          this.detailsForm.controls['consigneeAddress'].setValue(
            this.detailsOfCompany['companyDetails']['streetLine1'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['streetLine2'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['zipcode'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['city'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['province'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['country']
          );
          // this.saveQuoteDataObj['destinationAddress']['fullAddr'] =
          //   this.detailsOfCompany['companyDetails']['streetLine1'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['streetLine2'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['zipcode'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['city'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['province'] +
          //   ', ' +
          //   this.detailsOfCompany['companyDetails']['country'];
          this.consigneeFullAddr =
            this.detailsOfCompany['companyDetails']['streetLine1'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['streetLine2'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['zipcode'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['city'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['province'] +
            ', ' +
            this.detailsOfCompany['companyDetails']['country'];
        }
        this.saveQuoteDataObj['destinationAddress']['addrLine1'] =
          this.detailsOfCompany['companyDetails']['streetLine1'];
        this.saveQuoteDataObj['destinationAddress']['addrLine2'] =
          this.detailsOfCompany['companyDetails']['streetLine2'];
        this.saveQuoteDataObj['destinationAddress']['city'] =
          this.detailsOfCompany['companyDetails']['city'];
        this.saveQuoteDataObj['destinationAddress']['firstName'] =
          this.detailsOfCompany['companyDetails']['firstName'];
        this.saveQuoteDataObj['destinationAddress']['lastName'] =
          this.detailsOfCompany['companyDetails']['lastName'];
        this.saveQuoteDataObj['destinationAddress']['state'] =
          this.detailsOfCompany['companyDetails']['province'];
        this.countryConsigneeString = this.saveQuoteDataObj[
          'destinationAddress'
        ]['country'] = this.detailsOfCompany['companyDetails']['country'];
        this.saveQuoteDataObj['destinationAddress']['company'] =
          this.detailsOfCompany['companyDetails']['companyName'];
        this.saveQuoteDataObj['destinationAddress']['zipCode'] =
          this.detailsOfCompany['companyDetails']['zipcode'];
        this.saveQuoteDataObj['destinationAddress']['latitude'] = parseFloat(
          this.detailsOfCompany['companyDetails']['latitude']
        );
        this.saveQuoteDataObj['destinationAddress']['longitude'] = parseFloat(
          this.detailsOfCompany['companyDetails']['longitude']
        );

        this.modalService.dismissAll();
        this.displayCurrency();
      }

      this.modalService.dismissAll()
    }).catch((error) => {

      this.modalService.dismissAll()
      // this.toastr.error('Something went wrong.', 'Oops', { timeOut: 10000 });
    })
  }

  selectShipperAddress(e) {
    this.found = '';
    if (e.length > 2) {
      this.found = 'searching...';
      this.addressData = [];

      let url = ServerURLS.getAddressBookgetquote.action + `0/10/?searchKey=${e}&uniqueCompany=true`;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((a) => {
        if (a['totalPages'] === 0) {
          this.addressData = [];
          this.found = 'No data found.';

          this.modalService.dismissAll()
          return;
        }

        this.modalService.dismissAll()
        this.addressData = a['response'];
        this.found = '';
      }).catch((error) => {

        this.modalService.dismissAll()
        if (error['status'] === 404) {
          this.found = 'No data found.';

          this.modalService.dismissAll()
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      })

    }
    if (e.length <= 2) {
      this.found = '';
      this.addressData = this.defaultaddressbookdata;
    }
  }

  selectConsigneeAddress(e) {
    this.found2 = '';
    if (e.length > 2) {
      this.found2 = 'searching...';
      this.ConsigneeAddressData = [];

      let url = ServerURLS.getAddressBookgetquote.action + `0/10/?searchKey=${e}&uniqueCompany=true`;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((a) => {
        if (a['totalPages'] === 0) {
          this.ConsigneeAddressData = [];

          this.modalService.dismissAll();
          this.found2 = 'No data found.';
          return;
        }
        this.ConsigneeAddressData = a['response'];
        this.found2 = '';
      }).catch((error) => {


        this.modalService.dismissAll()
        if (error['status'] === 404) {
          this.found2 = 'No data found.';

          this.modalService.dismissAll();
          return;
        }
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      })


    }
    if (e.length <= 2) {
      this.found2 = '';
      this.ConsigneeAddressData = this.defaultaddressbookdata;
    }
  }

  // New Development
  backToDashboard() {
    this.router.navigate(['/shipcarte/dashboard']);
  }

  searchAccessorial(e) {
    let searchValue = e.toLocaleLowerCase();
    if (e.length > 2) {
      this.accessorialData = this.accessorialData.filter((element) => {
        if (element.serviceName.toLocaleLowerCase().includes(searchValue)) {
        }
        return element.serviceName.toLocaleLowerCase().includes(searchValue);
      });
    }
  }

  increamentByOne(i, measureMentUnit) {
    if (measureMentUnit == 'pieceCount') {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('pieceCount')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                .pieceCount
                ? (<FormArray>this.shipmentItemsForm.get('inputFields')).value[
                  i
                ].pieceCount
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'length') {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('length')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                .length
                ? (<FormArray>this.shipmentItemsForm.get('inputFields')).value[
                  i
                ].length
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'width') {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('width')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                .width
                ? (<FormArray>this.shipmentItemsForm.get('inputFields')).value[
                  i
                ].width
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'height') {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('height')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                .height
                ? (<FormArray>this.shipmentItemsForm.get('inputFields')).value[
                  i
                ].height
                : 0) + 1
            ).toFixed(2)
          )
        );
    } else if (measureMentUnit == 'weight') {
      (
        (this.shipmentItemsForm.get('inputFields') as FormArray).at(
          i
        ) as FormGroup
      )
        .get('weight')
        .patchValue(
          parseFloat(
            (
              ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                .weight
                ? (<FormArray>this.shipmentItemsForm.get('inputFields')).value[
                  i
                ].weight
                : 0) + 1
            ).toFixed(2)
          )
        );
    }
  }

  decreamentByOne(i, measureMentUnit) {
    if (measureMentUnit == 'length') {
      if (
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
          .length &&
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i].length >
        1
      ) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('length')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                  .length
                  ? (<FormArray>this.shipmentItemsForm.get('inputFields'))
                    .value[i].length
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'pieceCount') {
      if (
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
          .pieceCount &&
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
          .pieceCount > 1
      ) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('pieceCount')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                  .pieceCount
                  ? (<FormArray>this.shipmentItemsForm.get('inputFields'))
                    .value[i].pieceCount
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'width') {
      if (
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i].width &&
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i].width >
        1
      ) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('width')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                  .width
                  ? (<FormArray>this.shipmentItemsForm.get('inputFields'))
                    .value[i].width
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'height') {
      if (
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
          .height &&
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i].height >
        1
      ) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('height')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                  .height
                  ? (<FormArray>this.shipmentItemsForm.get('inputFields'))
                    .value[i].height
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    } else if (measureMentUnit == 'weight') {
      if (
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
          .weight &&
        (<FormArray>this.shipmentItemsForm.get('inputFields')).value[i].weight >
        1
      ) {
        (
          (this.shipmentItemsForm.get('inputFields') as FormArray).at(
            i
          ) as FormGroup
        )
          .get('weight')
          .patchValue(
            parseFloat(
              (
                ((<FormArray>this.shipmentItemsForm.get('inputFields')).value[i]
                  .weight
                  ? (<FormArray>this.shipmentItemsForm.get('inputFields'))
                    .value[i].weight
                  : 0) - 1
              ).toFixed(2)
            )
          );
      }
    }
  }

  openModel() {
    // this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop,', windowClass: 'dark-model', backdrop: 'static' })
  }
  unNumber:any;
  packageGroupOptions = [
    { value: 'na', label: 'N/A' },
    { value: 'i', label: 'I' },
    { value: 'ii', label: 'II' },
    { value: 'iii', label: 'III' },
];

// setHazmatDetails(data: any, i: number, j?:number): void {
//   const inputFieldsArray = this.shipmentItemsForm.get('inputFields') as FormArray;
//   const hazMatDetailsArray = inputFieldsArray.at(i).get('hazMatDetails') as FormArray;
// debugger

// hazMatDetailsArray.at(j).patchValue({
//       unNumber: data.unNumber,
//       properShippingName: data.properShippingNames,
//       hazardClass: data.hazardClass,
//       packageGroup: data.packageGroup,
//       hazardPackagingType: data.hazardPackagingType
//     });
  
// }
setHazmatDetails(data: any, i: number, j?: number): void {
  const inputFieldsArray = this.shipmentItemsForm.get('inputFields') as FormArray;
  if (!inputFieldsArray.at(i)) {
    console.error(`No inputFields found at index ${i}`);
    return;
  }
  const hazMatDetailsArray = inputFieldsArray.at(i).get('hazMatDetails') as FormArray;
  if (!hazMatDetailsArray.at(j)) {
    console.error(`No hazMatDetails found at index ${j}`);
    return;
  }
  hazMatDetailsArray.at(j).patchValue({
    unNumber: data.unNumber,
    properShippingName: data.properShippingNames,
    hazardClass: data.hazardClass,
    packageGroup: data.packageGroup,
    hazardPackagingType: data.hazardPackagingType,
  });
}

handleInput(event: Event,i:number,j:number):void {
    
  const hazmatUnNumberControl = this.shipmentItemsForm.get(`inputFields.${i}.hazMatDetails.${j}.unNumber`);

  if (hazmatUnNumberControl) {
      const hazmatUnNumberValue = hazmatUnNumberControl.value as string;

      if (hazmatUnNumberValue.length === 4) {
          this.getHazmatDetails(hazmatUnNumberValue,i,j);
      }
  }
}
getHazmatDetails(hazmatUnNumberValue: string,i:number,j) {
  let url = ServerURLS.getHazmatDetails.action + '?un_number=' + hazmatUnNumberValue;
  let promise = this.httpService.getHttpService(url, '');
  promise.then((result)=>{
    console.log(result);
    this.setHazmatDetails(result,i,j);
  })
}
@ViewChild('contentWarn', undefined) confirmModal;
popupMsg=''
isCraditExit=false

getDisabled(){
  if (this.isCreditDisabled ) {
    this.popupMsg='Your credit has been disabled. Please contact the administrator.'
    this.isCraditExit=true;
    this.showPopup();
  } else if (parseFloat(this.availableCreditLimitInCAD )<=0||parseFloat(this.availableCreditLimitInUSD)<0) {
    this.popupMsg='The credit limit is not enough to cover the total charge.'
    this.isCraditExit=true;
    this.showPopup();
  } else{
    this.popupMsg=''
    this.isCraditExit=false;
    this.saveQuote()
  }
}
showPopup(){
  if (this.isCraditExit) {
    this.modalService.open(this.confirmModal, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', backdrop: 'static', windowClass: "my-custom-modal" }).result.then((result) => {
      if (result == 'Save click') {
      }
    }, (reason) => {
      
    });
  }
}
}

