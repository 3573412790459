
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StorageKeys } from 'src/app/models/storageKeys';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';





@Component({
  selector: 'app-all-invoice',
  templateUrl: './allinvoice.component.html',
  styleUrls: ['./allinvoice.component.scss']
})

export class AllInvoiceComponent implements OnInit {
  tabs = [
    { id: 1, tabName: "Customer Invoice" },
    { id: 2, tabName: "Partner Invoice" },
    { id: 3, tabName: "Invoice Processing" },
    { id: 4, tabName: "Contract Processing" },
    { id: 5, tabName: "Insurance Processing" }


   
  ];
  selectedTab: number = 1;

  ngOnInit(): void {
    localStorage.setItem('_adminreferrer','invoice');
    sessionStorage.setItem(StorageKeys.childOrderRedirectedFromPage,'invoice');
    sessionStorage.removeItem(StorageKeys.isInvoiceChildOrderDetail);
    this.listDataService.removeAllOrderListData()
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllDataOfPartnerList()
    this.listDataService.removeAllDataOfQuoteHistory()
    this.listDataService.removeAllSalesRepData();
    this.listDataService.removeAllDataOfStandardChargeList();
    this.listDataService.removeAllDataOfSettingpackageManageer();
    this.listDataService.removeAllDataSettingUserManagement();
    this.setTabId()
  }
  constructor(
    private router: Router,
    private listDataService: ListDataServiceService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setTabId();
      });
  }
  selectTab(id) {
    // remove because this is used to show listing of multiple child invoices ************
    sessionStorage.removeItem("_adminInvoiceParentInvID");
    sessionStorage.removeItem(StorageKeys.isInvoiceChildOrderDetail);
    
    this.selectedTab = id;
    if (id == 1) {
      this.router.navigateByUrl('shipcarte/allinvoices')

    }
    
    else if (id == 2) {
      this.router.navigateByUrl('shipcarte/allinvoices/partnerinvoice')

    }
    else if (id == 3) {
      this.router.navigateByUrl('shipcarte/allinvoices/invoice-processing/toAudit')

    }
    else if (id == 4) {
      this.router.navigateByUrl('shipcarte/allinvoices/contractprocessing')

    }
    else if (id == 5) {
      this.router.navigateByUrl('shipcarte/allinvoices/insuranceprocessing')

    }

    // if (id == 1) {
    //   this.allInvoices = true;
    //   this.invoiceProcessing = false;
    //   this.contractProcessing = false;
    // }
    // else if (id == 2) {
    //   this.allInvoices = false;
    //   this.invoiceProcessing = true;
    //   this.contractProcessing = false;
    // }
    // else {
    //   this.allInvoices = false;
    //   this.invoiceProcessing = false;
    //   this.contractProcessing = true;
    // }
  }
  setTabId() {
    let urlArray = window.location.href.split('/');
    if (urlArray[urlArray.length - 1] === 'contractprocessing' || urlArray[urlArray.length - 2] === 'contractprocessing') {
      this.selectedTab = 4
    } else if (urlArray[urlArray.length - 1] === 'allinvoices') {
      this.selectedTab = 1;
    } 
    else if (urlArray[urlArray.length - 1] === 'partnerinvoice' || urlArray[urlArray.length - 2] === 'partnerinvoice') {
      this.selectedTab = 2
    }
    else if (urlArray[urlArray.length - 1] === 'toAudit' || urlArray[urlArray.length - 1] === 'toInvoice') {
      this.selectedTab = 3
    }
    else if (urlArray[urlArray.length - 1] === 'insuranceprocessing' || urlArray[urlArray.length - 2] === 'insuranceprocessing') {
      this.selectedTab = 5
    }
  }
}


